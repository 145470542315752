import React from 'react';

import {Travel} from '../../Enum/Models';
import HotelItem from '../Accomodation/HotelItem'
import FlightItem from '../Flight/FlightItem';

 const ListItem = props => {

    let {start:firstIndex ,last,data}= props;
    
    let lastIndex = last;
    if (data.length < last+1){
        lastIndex = data.length-1;
    }

    const items = data.slice(firstIndex,lastIndex+1);

    return(
        <>
            {(props.type===Travel.hotel)?
            items.map(item=><HotelItem model={item} key={item.id} duration={props.duration} />)
            :
            items.map(item=><FlightItem 
                hasExtraBaggage={item.hasExtraBaggage && (1+1==="%%%extraBaggageDeactive")} 
                domestic={props.domestic}
                model={item} 
                key={item.key}
            />)}
        </>
    )
}

export default ListItem;