import React from "react";
import { connect } from "react-redux";

const NaminAmazingOffers = props =>{
    const {Dictionary} = props;
    const list = [
        Dictionary.FreeProfessionalSupport,
        Dictionary.FreeExtraBaggage,
        Dictionary.Rebookable,
        Dictionary.EasierToChangeRules,
        Dictionary.FlexibleTicketService,
        Dictionary.PossibilityToChangeDatesOrRefund
    ];
    return(<section className="namintravel-amazing-offer-section">
    <div className="page-container">
    <h3 className="namintravel-amazing-offer-title">{Dictionary.amazingOffers}</h3>
        <section className="namintravel-amazing-offer-inner">
            <div className="float-row">
                <div className="col-small-12 col-medium-6 min-height-1" />
                
                <div className="col-small-12 col-medium-6 min-height-1" >
                    <ul className="namintravel-amazing-offer-list">
                        {list.map(item => <li key={item}>{item}</li>)}
                    </ul>
                </div>
            </div>
        </section>
    </div>
</section>)
}

const MapStateToProps = (state) => ({
    Dictionary: state.VisualData.Dictionary
  });

export default connect(MapStateToProps)(NaminAmazingOffers);