import React from "react";
import moment from "moment-jalaali";
import queryString from "query-string";
import { connect } from "react-redux";
import { GetReserveById } from "../../Actions/PaymentAction";
import { gatewayReport } from "../../Actions/PaymentAction";
import { setFlightSelected } from "../../Actions/FlightAction";
import { selectedHotel } from "../../Actions/HotelAction";
import { Payment } from "../../Enum/Models";
import Price from "../../components/Common/Price";
import Loading from "../../components/Common/Loading";
import { editMessage,showAccountWindowIR } from "../../Actions/VisualDataAction";
import { Alert } from "antd";
import FlighSummary from "../../components/Flight/FlighSummary";
import SummaryHotel from "../../components/Accomodation/SummaryHotel";
import PackageSummary from "../../components/Package/PackageSummary";
import DomesticHotelSummery from "../../components/domesticHotel/DomesticHotelSummery";
import LayoutConfig from "../../Config/layoutConfig";
import { Link } from "react-router-dom";
import DomesticFlightRefund from "../../components/Flight/DomesticFlightRefund";
import StickySides from "../../components/Common/StickySides";

class ReserveDetails extends React.Component {
  state = {
    farePrice: null,
    bookingError: false,
    reserveID: null,
    requiredLoginModalVisibility:false,
    refundIsClicked:false,
    showDomesticFlightRefundModal:false
  };
  getInfromation = () => {
    this.props.setFlightSelected(null);
    this.props.selectedHotel(null);
    let queryParams = this.props.location.search?.split("#")[0];
    const reserve_id = queryParams.split("reserveId=")[1]?.split("&")[0];
    const reserve_userName = queryParams.split("userName=")[1]?.split("&")[0]; 
    let parameters = queryString.parse(this.props.location.search);
    if (parameters.hasError === "true") {
      this.setState({
        bookingError: true,
      });
    }
    let params = new URLSearchParams();
    params.append("reserveId", reserve_id);
    params.append("userName", reserve_userName);
    this.props.GetReserveById(params);
    // this.props.gatewayReport({ ...parameters });
    this.setState({
      reserveID: reserve_id,
    });
  };
  componentDidMount() {
    this.props.editMessage(false);
    window.scrollTo(0, 0);
    this.getInfromation();
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.location.search !== this.props.location.search){
      this.props.editMessage(false);
      window.scrollTo(0, 0);
      this.getInfromation();
    }

    if (
      this.props.flightInfo !== null && this.props.flightInfo !== prevProps.flightInfo
    ) {
      let perPrice = [];
      let flightInfoCurrency = this.props.flightInfo.currency;
      this.props.flightInfo.passengersFare.forEach((item) => {
        perPrice[item.passengerTypeQuantity.code] = {
          totalTaxes: item.fare.totalTaxes,
          totalFare: item.fare.totalFare,
          baseFare: item.fare.baseFare,
        };
      });
      let passengerInfo = this.props.flightInfo.passengers;
      passengerInfo.forEach((itemPass) => {
        itemPass["baseFare"] = perPrice[itemPass.code].baseFare;
        itemPass["totalTaxes"] = perPrice[itemPass.code].totalTaxes;
        itemPass["totalFare"] = perPrice[itemPass.code].totalFare;
        itemPass["currency"] = flightInfoCurrency;
      });
      this.setState({
        farePrice: passengerInfo,
      });
    }

    if (
      this.props.packageInfo !== null && this.props.packageInfo !== prevProps.packageInfo
    ) {
      let perPrice = [];
      let packageInfoCurrency = this.props.packageInfo.currency;
      this.props.packageInfo.reserveFlightInfo.passengersFare.forEach((item) => {
        perPrice[item.passengerTypeQuantity.code] = {
          totalTaxes: item.fare.totalTaxes,
          totalFare: item.fare.totalFare,
          baseFare: item.fare.baseFare,
        };
      });
      let passengerInfo = this.props.packageInfo.reserveFlightInfo.passengers;
      passengerInfo.forEach((itemPass) => {
        itemPass["baseFare"] = perPrice[itemPass.code].baseFare;
        itemPass["totalTaxes"] = perPrice[itemPass.code].totalTaxes;
        itemPass["totalFare"] = perPrice[itemPass.code].totalFare;
        itemPass["currency"] = packageInfoCurrency;
      });
      this.setState({
        farePrice: passengerInfo,
      });
    }

    if (prevProps.Verification !== this.props.Verification){
      if (this.props.Verification && this.state.refundIsClicked){
        this.setState({showDomesticFlightRefundModal:true});
      }else{
        this.setState({
          requiredLoginModalVisibility:false,
          refundIsClicked:false,
          showDomesticFlightRefundModal:false
        })
      }
    }

  }

  phoneItem = (value)=>{
    if (process.env.REACT_APP_THEME === "NAMINTRAVELCOM" || process.env.REACT_APP_THEME ==='AYANAIRCOM'){
      return (
        <a href={`https://wa.me/${value.replace(/ +/g, "").replace(/[{(+)}]/g, '').replace(/-/g , "")}`} className="pull-end" dir="ltr" rel="noopener noreferrer" target="_blank" >
        <span className="margin-end-half itours-icon whatsapp icon-small" />
          <span dir="ltr">{value} ({this.props.Dictionary.WhatsAppOnly})</span>
        </a>
      );
    }
    return (
      <span className="pull-end" dir="ltr" >
        <span className="margin-right-light itours-icon red-phon-icon icon-small" />
        <span dir="ltr">{value} </span>
      </span>
    )
  };

  refundHandler = () =>{
    if (this.props.Verification){
      this.setState({showDomesticFlightRefundModal:true});
    }else{
      this.setState({requiredLoginModalVisibility:true,refundIsClicked:true});
    }
  }

  updateReserveState = () => {
    this.props.editMessage(false);
    window.scrollTo(0, 0);
    this.getInfromation();
  }

  render() {
    // let checkIn=null;
    // let checkOut=null;
    const { Dictionary } = this.props;
    let night = 0;
    let totalPrice = 0;
    const { respond, flightInfo, voucherDetails,packageInfo ,domesticHotelReserveInfo,domesticHotelInfo} = this.props;
    if (voucherDetails != null) {
      night = voucherDetails.date.duration;
      // let cashDateIN = new Date(voucherDetails.date.checkIn);
      // checkIn = cashDateIN.getFullYear() + "/" + (cashDateIN.getMonth() + 1) + "/" + cashDateIN.getDate();
      // let cashDateOUT = new Date(voucherDetails.date.checkOut);
      // checkOut = cashDateOUT.getFullYear() + "/" + (cashDateOUT.getMonth() + 1) + "/" + cashDateOUT.getDate();
      if (voucherDetails.accommodation) {
        voucherDetails.accommodation.rooms.forEach((item) => {
          totalPrice += parseFloat(item.salePrice);
        });
      }
    }
    let paymentArray = [];
    if (this.props.paymentInformation) {
      for (let objIndex in this.props.paymentInformation) {
        paymentArray.push(this.props.paymentInformation[objIndex]);
      }
    }
    let linkToPayment = null;
    const duration =flightInfo ? moment.utc(flightInfo.expirationTime).diff(flightInfo.currentTime || Date.now()) : undefined;
    if (duration && duration > 0 && flightInfo.reserveStatus==="Pending"){
      linkToPayment = (<div className="clearfix">
        <p className="pull-start">{Dictionary.YourReservationIsReadyToBePaid}.</p>
        <Link
          to={`/payment/reserve?reserveId=${this.state.reserveID}&type=flight`}
          className="button color-1-btn pull-end"
        >
          {Dictionary.payment}
        </Link>
      </div>)
    }

    if(voucherDetails && voucherDetails.status === Payment.Pending){
      linkToPayment = (<div className="clearfix">
        <p className="pull-start">{Dictionary.YourReservationIsReadyToBePaid}.</p>
        <Link
          to={`/payment/reserve?reserveId=${this.state.reserveID}`}
          className="button color-1-btn pull-end"
        >
          {Dictionary.payment}
        </Link>
      </div>)
    }

    let voucherStatusMessage = "";
    let voucherStatusIconClass = null;
    let flightStatusBlock = null;
    
    if (voucherDetails){

      switch (voucherDetails.status) {
        case Payment.Pending:
          voucherStatusMessage = Dictionary.paymentPending;
          voucherStatusIconClass = "peyment-warning";
          break;
        case Payment.Canceled:
          voucherStatusMessage = Dictionary.canceled;
          voucherStatusIconClass = "peyment-error";
          break;
        case Payment.Failed:
          voucherStatusMessage = Dictionary.Failed;
          voucherStatusIconClass = "peyment-error";
          break;
        case Payment.Issued:
        case Payment.PaymentSuccessful:
          voucherStatusMessage = Dictionary.Issued;
          voucherStatusIconClass = "peyment-ok";
          break;
        case Payment.Refunded:
          voucherStatusMessage = Dictionary.Refunded;
          voucherStatusIconClass = "peyment-error";
          break;
        case Payment.WebServiceUnsuccessful:
        case Payment.WebServiceCancel:
          voucherStatusMessage = Dictionary.WebServiceUnsuccessful;
          voucherStatusIconClass = "peyment-error";
          break;
        default:
          voucherStatusMessage = '';
          voucherStatusIconClass = null;
      }
    }
    if (domesticHotelReserveInfo) {
      switch (domesticHotelReserveInfo.status) {
        case Payment.Pending:
          voucherStatusMessage = Dictionary.paymentPending;
          voucherStatusIconClass = "peyment-warning";
          break;
        case Payment.Canceled:
          voucherStatusMessage = Dictionary.canceled;
          voucherStatusIconClass = "peyment-error";
          break;
        case Payment.Failed:
          voucherStatusMessage = Dictionary.Failed;
          voucherStatusIconClass = "peyment-error";
          break;
        case Payment.Issued:
        case Payment.PaymentSuccessful:
          voucherStatusMessage = Dictionary.Issued;
          voucherStatusIconClass = "peyment-ok";
          break;
        case Payment.Refunded:
          voucherStatusMessage = Dictionary.Refunded;
          voucherStatusIconClass = "peyment-error";
          break;
        case Payment.WebServiceUnsuccessful:
        case Payment.WebServiceCancel:
          voucherStatusMessage = Dictionary.WebServiceUnsuccessful;
          voucherStatusIconClass = "peyment-error";
          break;
        default:
          voucherStatusMessage = '';
          voucherStatusIconClass = null;
      }
    }    

    if(flightInfo){
      switch (flightInfo.reserveStatus) {
        case Payment.Pending:
          flightStatusBlock = (
            <div>
              <span className="itours-icon icon-xlarge peyment-warning margin-end-half" />
              <span>{Dictionary.paymentPending}</span>
            </div>
          );
          break;
        case Payment.UnConfirmed:
        case Payment.InProgress:
          flightStatusBlock = (
            <div>
              <span className="itours-icon icon-xlarge peyment-warning margin-end-half" />
              <span>{Dictionary.checking}</span>
              <p className="semi-bold font-18 margin-top">
                {this.props.LAN === 'IR' && <span className="margin-end-light">{flightInfo?.reserver.firstName} عزیز،</span>}
                {Dictionary.YourTicketHasBeenRegisteredAndOurSupportTeamIsIssuingItYourTicketWillBeSentToYouAsSoonAsItIsIssued}
              </p>
            </div>
          );
          break;
        case Payment.Refunded:
        case Payment.PaidBack:
          flightStatusBlock = (
            <div>
              <span className="itours-icon icon-xlarge peyment-warning margin-end-half" />
              {Dictionary.Refunded}
              <p className="semi-bold font-18 margin-top">
                {this.props.LAN === 'IR' && <span className="margin-end-light">{flightInfo?.reserver.firstName} عزیز،</span>}
                {Dictionary.TheAmountWasCreditedToYourAccount}.
              </p>
            </div>
          );
          break;
        case Payment.Canceled:
          flightStatusBlock = (
            <div>
              <span className="itours-icon icon-xlarge peyment-error margin-end-half" />
              <span>{Dictionary.canceled}</span>
            </div>
          );
          break;
        case Payment.Failed:
          flightStatusBlock = (
            <div>
              <span className="itours-icon icon-xlarge peyment-error margin-end-half" />
              <span>{Dictionary.Failed}</span>
            </div>
          );
          break;
        case Payment.Issued:
          flightStatusBlock = (
            <div>
              <span className="itours-icon icon-xlarge peyment-ok margin-end-half" />
              <span>{Dictionary.Issued}</span>
              <p className="semi-bold font-18 margin-top">
                {this.props.LAN === 'IR' && <span className="margin-end-light">{flightInfo?.reserver.firstName} عزیز،</span>}
                {Dictionary.YourTicketHasBeenIssuedSuccessfully}.
              </p>
            </div>
          );
          break;
        case Payment.PaymentSuccessful:
          flightStatusBlock = (
            <div>
              <span className="itours-icon icon-xlarge peyment-ok margin-end-half" />
              <span>{Dictionary.successfulPayment}</span>
            </div>
          );
          break;
        case Payment.WebServiceUnsuccessful:
          flightStatusBlock = (
            <div>
              <span className="itours-icon icon-xlarge peyment-error margin-end-half" />
              <span>{Dictionary.WebServiceUnsuccessful}</span>
              <p className="semi-bold font-18 margin-top">
                <span className="itours-icon circle-error margin-end-half" />
                {this.props.LAN === 'IR' && <span className="margin-end-light">{flightInfo?.reserver.firstName} عزیز،</span>}
                {Dictionary.SorryYourTicketWasNotIssued}. {Dictionary.PleaseContactOurSupportTeamBeforeTryingAgainForBooking}.
                {this.props.LAN === 'IR' &&
                  '  از این که با شکیبایی خود ما را یاری میفرمایید بسیار سپاسگزاریم.'}
              </p>
            </div>
          );
          break;
        default:
          flightStatusBlock = null;
      }
    }

    const domesticFlightRefundIsEnabled = flightInfo && (process.env.REACT_APP_SERVER_LOCALSERVER === "santana.imt.as" || process.env.REACT_APP_SERVER_LOCALSERVER === "itours.ir") && flightInfo.reserveStatus === Payment.Issued && flightInfo.isDomestic && flightInfo.flightType === "Systemic";
    
    let reserveInformation, hotelInformation;

    if (domesticHotelInfo) {
      hotelInformation = {
        image: {
          url: domesticHotelInfo.ImageUrl,
          alt: domesticHotelInfo.ImageAlt,
          title: domesticHotelInfo.ImageTitle
        },
        name: `${domesticHotelInfo.HotelCategoryName} ${domesticHotelInfo.HotelName} ${domesticHotelInfo.CityName}`,
        rating: domesticHotelInfo.HotelRating,
        address: domesticHotelInfo.Address,
        TopSelling: domesticHotelInfo.TopSelling,
        Url: domesticHotelInfo.Url,
        CityId: domesticHotelInfo.CityId
      }
    }
    if (domesticHotelReserveInfo) {
      reserveInformation = {
        checkin: domesticHotelReserveInfo.checkin,
        checkout: domesticHotelReserveInfo.checkout,
        duration: domesticHotelReserveInfo.count,
        rooms: domesticHotelReserveInfo.rooms.map(roomItem => ({
          name: roomItem.name,
          board: roomItem.boardCode,
          cancellationPolicyStatus: roomItem.cancellationPolicyStatus,
          bed: roomItem.bed,
          extraBed: roomItem.extraBed,
          pricing: roomItem.pricing,
          maxChildAge: roomItem.maxChildAge,
          maxInfantAge: roomItem.maxInfantAge
        })),
        salePrice: domesticHotelReserveInfo.rooms.reduce((totalPrice, roomItem) => {
          const roomItemPrice = roomItem.pricing.find(
            item => item.type === "Room" && item.ageCategoryType === "ADL"
          )?.amount;
          if (roomItemPrice) {
            return totalPrice + +roomItemPrice
          } else {
            return null;
          }
        }, 0),
        boardPrice: domesticHotelReserveInfo.rooms.reduce((totalPrice, roomItem) => {
          const roomItemPrice = roomItem.pricing.find(
            item => item.type === "RoomBoard" && item.ageCategoryType === "ADL"
          )?.amount;
          if (roomItemPrice) {
            return totalPrice + +roomItemPrice
          } else {
            return null;
          }
        }, 0)
      }
    }

    return !respond ? (
      <Loading fullPage description={Dictionary.loadingReservationInformation + ' ...'} />
    ) : (      
      <div className="section-vertical-padding reserve-details-page">

        {this.state.showDomesticFlightRefundModal && domesticFlightRefundIsEnabled && <DomesticFlightRefund reserveID = {this.state.reserveID} username={flightInfo.reserver.username} onClose={()=>this.setState({showDomesticFlightRefundModal:false})}  updateReserveState={this.updateReserveState} />}
        
        {domesticFlightRefundIsEnabled && this.state.requiredLoginModalVisibility &&  <div className="itours-modal login-to-book-modal visible">
          <div className="modal-overlay" onClick={()=>{this.setState({requiredLoginModalVisibility:false})}} />
          <div className="modal-inner small-width">
            <div className="modal-main">
              <span className="itours-icon user-login icon-xxlarge" />
              <h3 className="normal-title">{this.props.userData && this.props.userData.userName ? "please Confirm Your Email To Continue" :Dictionary.pleaseLoginToContinue} ...</h3>
              <p className="text-center gray semi-bold margin-bottom-large">
                <span className="itours-icon info-icon icon-small margin-end-light" />
                جهت ادامه فرایند استرداد بلیط وارد حساب کاربری خود شوید.
              </p>
              <div className="text-center margin-top-large margin-bottom-large">
                {this.props.userData && this.props.userData.userName ?
                <button type="button" className="button blue-btn large-btn" onClick={()=>{window.location.reload();}} >
                  {Dictionary.Reload}
                </button>
                :<button
                  type="button"
                  className="button red-btn large-btn"
                  onClick={()=>{
                    this.setState({requiredLoginModalVisibility:false});
                    this.props.showAccountWindowIR("usernameModal");
                  }}
                >
                  {Dictionary.login}
                </button>}               
              </div>
            </div>
          </div>
        </div>}
        <div className="page-container ">
          <h2 className="page-title">{Dictionary.reservationDetails}</h2>
          {this.props.flightError && this.props.flightError === '2' && (
            <Alert message={Dictionary.error} description={Dictionary.SoryThereIsAProblemInTheReservationCallUs} type="error" showIcon />
          )}
          <StickySides
            reverseOrderInMobile
            main={(
              <>
              {flightInfo && (
                <>
                  {this.state.bookingError === true && (
                    <div className={'bg-white box-border card-padding information margin-bottom'}>
                      <div className="clearfix">
                        <div className="pull-start">
                          <h3 className="normal-title">
                            <span className="itours-icon icon-xlarge peyment-error margin-end-half" />
                            <span>{Dictionary.SoryThereIsAProblemInTheReservationCallUs}</span>
                          </h3>
                        </div>
                      </div>
                    </div>
                  )}

                  <div className={'bg-white box-border card-padding information margin-bottom'}>
                    <div className="clearfix">
                      <div className="pull-start">
                        <h3 className="normal-title">
                          {flightStatusBlock}
                        </h3>
                        {/*<div className="margin-bottom">*/}
                        {/*        <span className="inline-block">*/}
                        {/*        {Dictionary.trackingCode}*/}
                        {/*            :*/}
                        {/*        </span>*/}
                        {/*    <span className="inline-block margin-end"> {flightInfo.reserveId} </span>*/}
                        {/*</div>*/}
                      </div>

                      {flightInfo.reserveStatus === Payment.Issued && (
                        <>
                          <a
                            href={
                              process.env.REACT_APP_THEME === "NAMINTRAVELCOM"?
                                `https://voucher.namintravel.com/en/flight/Ticket2?reserveid=${this.state.reserveID}&username=${flightInfo.reserver.username}`
                                : process.env.REACT_APP_THEME ==='AYANAIRCOM'?
                                `https://voucher.ayanair.net/en/flight/ayanairTicket?reserveId=${this.state.reserveID}&username=${flightInfo.reserver.username}`
                                : this.props.LAN === 'IR'
                                ? `https://voucher.itours.ir/en/flight/irTicket3?reserveid=${this.state.reserveID}&username=${flightInfo.reserver.username}`
                                : `https://voucher.imt.as/en/flight/Ticket?reserveid=${this.state.reserveID}&username=${flightInfo.reserver.username}`
                            }
                            target="_blank"
                            rel="noopener noreferrer"
                            download
                            className="button color-1-btn pull-end">
                            {Dictionary.ticket}
                          </a>

                          {(process.env.REACT_APP_THEME === "NAMINTRAVELCOM" || process.env.REACT_APP_THEME ==='AYANAIRCOM') && <a
                            href={
                              process.env.REACT_APP_THEME === "NAMINTRAVELCOM"?
                              `https://voucher.namintravel.com/en/Factor/Flight?reserveId=${this.state.reserveID}&username=${flightInfo.reserver.username}`
                              :`https://voucher.ayanair.net/en/Factor/Flight?reserveId=${this.state.reserveID}&username=${flightInfo.reserver.username}`
                            }
                            target="_blank"
                            rel="noopener noreferrer"
                            download
                            className="button grey-btn pull-end margin-end-half"
                          >
                            {Dictionary.Invoice}
                          </a>}

                          {domesticFlightRefundIsEnabled && <button 
                            type="button" 
                            className="button red-btn pull-end margin-end-half"
                            onClick={this.refundHandler}
                            >
                            درخواست استرداد
                          </button>}
                        </>
                      )}
                    </div>
                    {linkToPayment}

                    {flightInfo.reserveStatus === Payment.PaymentSuccessful && (
                      <p>{Dictionary.YourPaymentWasSuccessfulAndYourTicketWillBeSentToYourEmailOnceItIsIssued}.</p>
                    )}
                    <h5 className="normal-title">{Dictionary.ReserverInformartion}</h5>
                    <div
                      className="float-row"
                      style={{
                        border: '1px solid gray',
                        backgroundColor: '#e9e9e9',
                      }}>
                      <div className="col-small-12 col-medium-6">
                        <b>{Dictionary.trackingNumber}</b>
                      </div>
                      <div className="col-small-12 col-medium-6">
                        <b>{this.state.reserveID}</b>
                      </div>
                    </div>
                    <div>
                      <div>
                        <span className="item-label">{Dictionary.gender}: </span> {flightInfo.reserver.gender ? Dictionary.male : Dictionary.female}
                      </div>
                      <div>
                        <span className="item-label">{Dictionary.firstName}: </span> {flightInfo.reserver.firstName}
                      </div>
                      <div>
                        <span className="item-label">{Dictionary.lastName}: </span> {flightInfo.reserver.lastName}
                      </div>
                      <div
                        className="margin-bottom padding-h-10"
                        style={{
                          border: '1px solid gray',
                          margin: '0 -7px 6px',
                          backgroundColor: '#e9e9e9',
                        }}>
                        <b>{Dictionary.BookingInformationsWillbeSentToThisNumberOrEmail}</b>
                      </div>
                      <div>
                        <span className="item-label">{Dictionary.email}: </span> {flightInfo.reserver.email}
                      </div>
                      <div>
                        <span className="item-label">{Dictionary.phoneNumber}: </span> <span dir="ltr">{flightInfo.reserver.phoneNumber}</span>
                      </div>
                    </div>
                  </div>
                  <div className={'passengerInformation margin-bottom'}>
                    <h5 className="normal-title">{Dictionary.price}</h5>
                    <div className="responsive-table-holder">
                      <div className="itours-responsive-table medium-table bg-white responsive-bg-transparent">
                        <div className="table-row-group">
                          <div className="table-row table-head">
                            <div>{Dictionary.row}</div>
                            <div>{Dictionary.firstName}</div>
                            <div>{Dictionary.lastName}</div>
                            {(process.env.REACT_APP_THEME === 'NAMINTRAVELCOM' || process.env.REACT_APP_THEME ==='AYANAIRCOM') ? (
                              <>
                                <div>{Dictionary.wheelchair}</div>
                                <div>{Dictionary.specialDiet}</div>
                              </>
                            ) : null}
                            <div>{Dictionary.baseFare}</div>
                            <div>{Dictionary.taxes}</div>
                            <div>{Dictionary.fare}</div>
                          </div>
                          {this.state.farePrice &&
                            this.state.farePrice.map((itemPass, indexPass) => {
                              return (
                                <div className="table-row" key={indexPass}>
                                  <div>
                                    <span className="responsive-label">{Dictionary.row}</span>
                                    <span> {indexPass + 1}</span>
                                  </div>
                                  <div>
                                    <span className="responsive-label">{Dictionary.firstName}</span>
                                    <span>{itemPass.firstName}</span>
                                  </div>
                                  <div>
                                    <span className="responsive-label">{Dictionary.lastName}</span>
                                    <span>{itemPass.lastName} </span>
                                  </div>

                                  {(process.env.REACT_APP_THEME === 'NAMINTRAVELCOM' || process.env.REACT_APP_THEME ==='AYANAIRCOM') ? (
                                    <>
                                      <div>
                                        <span className="responsive-label">{Dictionary.wheelchair}</span>
                                        <span>{itemPass.hasWheelChair ? <span className="itours-icon green-checkmark icon-small" /> : '-----'}</span>
                                      </div>
                                      <div>
                                        <span className="responsive-label">{Dictionary.specialDiet}</span>
                                        <span>{itemPass.mealType ? itemPass.mealType : '-----'}</span>
                                      </div>
                                    </>
                                  ) : null}
                                  <div>
                                    <span className="responsive-label">{Dictionary.baseFare}</span>
                                    <span className="nowrap">
                                      <Price currency={itemPass.currency}>{itemPass.baseFare}</Price>{' '}
                                    </span>
                                  </div>
                                  <div>
                                    <span className="responsive-label">{Dictionary.taxes}</span>
                                    <span className="nowrap">
                                      <Price currency={itemPass.currency}>{itemPass.totalTaxes}</Price>{' '}
                                    </span>
                                  </div>
                                  <div>
                                    <span className="responsive-label">{Dictionary.fare}</span>
                                    <span className="nowrap">
                                      <Price currency={itemPass.currency}>{itemPass.totalFare}</Price>
                                    </span>
                                  </div>
                                </div>
                              );
                            })}
                          <div className="table-row total-row">
                            <div>
                              <b className="no-wrap">{Dictionary.baseFare}</b>
                            </div>
                            <div />
                            <div />
                            {(process.env.REACT_APP_THEME === 'NAMINTRAVELCOM' || process.env.REACT_APP_THEME ==='AYANAIRCOM') ? (
                              <>
                                <div />
                                <div />
                              </>
                            ) : null}
                            <div />
                            <div />
                            <div>
                              <b className="no-wrap">
                                <Price currency={flightInfo.currency}>{flightInfo.flightFare.baseFare}</Price>
                              </b>
                            </div>
                          </div>
                          <div className="table-row total-row">
                            <div>
                              <b className="no-wrap">{Dictionary.totalTaxes}</b>
                            </div>
                            <div />
                            <div />
                            {(process.env.REACT_APP_THEME === 'NAMINTRAVELCOM' || process.env.REACT_APP_THEME ==='AYANAIRCOM') ? (
                              <>
                                <div />
                                <div />
                              </>
                            ) : null}
                            <div />
                            <div />
                            <div>
                              <b className="no-wrap">
                                <Price currency={flightInfo.currency}>{flightInfo.flightFare.totalTaxes}</Price>
                              </b>
                            </div>
                          </div>
                          <div className="table-row total-row">
                            <div>
                              <b className="purple no-wrap">{Dictionary.totalFare}</b>
                            </div>
                            <div />
                            <div />
                            {(process.env.REACT_APP_THEME === 'NAMINTRAVELCOM' || process.env.REACT_APP_THEME ==='AYANAIRCOM') ? (
                              <>
                                <div />
                                <div />
                              </>
                            ) : null}
                            <div />
                            <div />
                            <div>
                              <b className="purple no-wrap">
                                <Price currency={flightInfo.currency}>{flightInfo.flightFare.totalFare}</Price>
                              </b>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
              {voucherDetails && (
                <>
                  <div className="card-padding bg-white box-border margin-bottom">
                    <div className="clearfix margin-bottom-half">
                      <div className={`${voucherDetails.status === 'Issued' ?"pull-start":""}`}>
                        <h3 className="normal-title">
                          <div>
                            {voucherStatusIconClass && <span className={`itours-icon icon-xlarge margin-end-half ${voucherStatusIconClass}`} />}
                            <span>{voucherStatusMessage}</span>
                          </div>
                        </h3>
                        {this.props.LAN === 'IR' && (
                           voucherDetails.status === Payment.WebServiceCancel ||
                           voucherDetails.status === Payment.WebServiceUnsuccessful ||
                           voucherDetails.status === Payment.PriceChange ||
                           voucherDetails.status === Payment.Unavailable 
                        ) && <div className="font-18 semi-bold pair-row purple">
                          <p className="no-margin-bottom ">جهت بررسی وضعیت رزرو خود، حتما با پشتیبانی آیتورز تماس بگیرید.</p>
                          <span dir="ltr">(+98) 21 91008091 </span>
                        </div>}
                        <div className="margin-bottom">
                          <span className="inline-block">{Dictionary.trackingCode}:</span>
                          <span className="inline-block margin-end"> {voucherDetails.reserveId} </span>
                        </div>
                      </div>

                      {voucherDetails.status === 'Issued' && (
                        <>
                          <a
                            href={
                              process.env.REACT_APP_THEME === "NAMINTRAVELCOM"?
                              `https://voucher.namintravel.com/en/flight/Voucher2?reserveId=${voucherDetails.reserveId}&username=${voucherDetails.reserver.username}`
                              : process.env.REACT_APP_THEME ==='AYANAIRCOM'?
                              `https://voucher.ayanair.net/en/Hotel/Voucher?reserveId=${voucherDetails.reserveId}&username=${voucherDetails.reserver.username}`
                              : `http://voucher.imt.as/en/Hotel/Voucher?reserveId=${voucherDetails.reserveId}&username=${voucherDetails.reserver.username}`
                            }
                            download
                            target="_blank"
                            rel="noopener noreferrer"
                            className="button color-1-btn pull-end">
                            {Dictionary.GetVoucher}
                          </a>
                          {(process.env.REACT_APP_THEME === "NAMINTRAVELCOM" || process.env.REACT_APP_THEME ==='AYANAIRCOM') && <a
                            href={
                              process.env.REACT_APP_THEME === "NAMINTRAVELCOM"?
                              `https://voucher.namintravel.com/en/Factor/Hotel?reserveId=${voucherDetails.reserveId}&username=${voucherDetails.reserver?.username}`
                              :`https://voucher.ayanair.net/en/Factor/Hotel?reserveId=${voucherDetails.reserveId}&username=${voucherDetails.reserver?.username}`
                            }
                            target="_blank"
                            rel="noopener noreferrer"
                            download
                            className="button grey-btn pull-end margin-end-half"
                          >
                            {Dictionary.Invoice}
                          </a>}                              
                        </>
                      )}
                      {linkToPayment}
                    </div>
                    <h3 className="normal-title">{Dictionary.ReserverInformartion}</h3>
                    <div>
                      <div className="margin-bottom-light">
                        <b className="inline-block margin-end-light">{Dictionary.fullName}:</b>
                        <span className="inline-block">
                          {' '}
                          {voucherDetails.reserver?.firstName} {voucherDetails.reserver?.lastName}
                        </span>
                      </div>
                      <div className="margin-bottom-light">
                        <b className="inline-block margin-end-light">{Dictionary.email}:</b>
                        <span className="en-font inline-block" dir="ltr">
                          {' '}
                          {voucherDetails.reserver?.username}
                        </span>
                      </div>
                      <div>
                        <b className="inline-block margin-end-light">{Dictionary.mobileNumber}:</b>
                        <span className="inline-block" dir="ltr">
                          {' '}
                          {voucherDetails.reserver?.phoneNumber}
                        </span>
                      </div>
                    </div>
                  </div>
                  {/*reserver information*/}

                  {voucherDetails.accommodation.rooms[0].remarks && (
                    <div className=" margin-bottom bg-white box-border card-padding">
                      <h5 className="normal-title">{Dictionary.remarks}:</h5>
                      <div className="justified en-font" dir="ltr" dangerouslySetInnerHTML={{ __html: voucherDetails.accommodation.rooms[0].remarks }} />
                    </div>
                  )}
                </>
              )}
              {packageInfo && (
                <>
                  <div className={'bg-white box-border card-padding information margin-bottom'}>
                    <div className="clearfix">
                      <div className="pull-start">
                        <h3 className="normal-title">
                          {packageInfo.reserveFlightInfo.reserveStatus === Payment.Pending && (
                            <div>
                              <span className="itours-icon icon-xlarge peyment-warning margin-end-half" />
                              <span>{Dictionary.paymentPending}</span>
                            </div>
                          )}
                          {packageInfo.reserveFlightInfo.reserveStatus === Payment.Canceled && (
                            <div>
                              <span className="itours-icon icon-xlarge peyment-error margin-end-half" />
                              <span>{Dictionary.canceled}</span>
                            </div>
                          )}
                          {packageInfo.reserveFlightInfo.reserveStatus === Payment.Failed && (
                            <div>
                              <span className="itours-icon icon-xlarge peyment-error margin-end-half" />
                              <span>{Dictionary.Failed}</span>
                            </div>
                          )}
                          {packageInfo.reserveFlightInfo.reserveStatus === Payment.Issued && (
                            <div>
                              <span className="itours-icon icon-xlarge peyment-ok margin-end-half" />
                              <span>{Dictionary.Issued}</span>
                            </div>
                          )}
                          {packageInfo.reserveFlightInfo.reserveStatus === Payment.PaymentSuccessful && (
                            <div>
                              <span className="itours-icon icon-xlarge peyment-ok margin-end-half" />
                              <span>{Dictionary.Issued}</span>
                            </div>
                          )}
                          {packageInfo.reserveFlightInfo.reserveStatus === Payment.Refunded && (
                            <div>
                              <span className="itours-icon icon-xlarge peyment-error margin-end-half" />
                              <span>{Dictionary.Refunded}</span>
                            </div>
                          )}
                          {packageInfo.reserveFlightInfo.reserveStatus === Payment.WebServiceUnsuccessful && (
                            <div>
                              <span className="itours-icon icon-xlarge peyment-error margin-end-half" />
                              <span>{Dictionary.WebServiceUnsuccessful}</span>
                            </div>
                          )}
                        </h3>
                        {/*<div className="margin-bottom">*/}
                        {/*        <span className="inline-block">*/}
                        {/*        {Dictionary.trackingCode}*/}
                        {/*            :*/}
                        {/*        </span>*/}
                        {/*    <span className="inline-block margin-end"> {flightInfo.reserveId} </span>*/}
                        {/*</div>*/}
                      </div>
                    </div>
                    <h5 className="normal-title">{Dictionary.ReserverInformartion}</h5>
                    <div
                      className="float-row"
                      style={{
                        border: '1px solid gray',
                        backgroundColor: '#e9e9e9',
                      }}>
                      <div className="col-small-12 col-medium-6">
                        <b>{Dictionary.trackingNumber}</b>
                      </div>
                      <div className="col-small-12 col-medium-6">
                        <b>{this.state.reserveID}</b>
                      </div>
                    </div>
                    <div>
                      <div>
                        <span className="item-label">{Dictionary.gender}: </span>{' '}
                        {packageInfo.reserveFlightInfo.reserver.gender ? Dictionary.male : Dictionary.female}
                      </div>
                      <div>
                        <span className="item-label">{Dictionary.firstName}: </span> {packageInfo.reserveFlightInfo.reserver.firstName}
                      </div>
                      <div>
                        <span className="item-label">{Dictionary.lastName}: </span> {packageInfo.reserveFlightInfo.reserver.lastName}
                      </div>
                      <div
                        className="margin-bottom"
                        style={{
                          border: '1px solid gray',
                          marginBottom: '6px',
                          backgroundColor: '#e9e9e9',
                        }}>
                        <b>{Dictionary.BookingInformationsWillbeSentToThisNumberOrEmail}</b>
                      </div>
                      <div>
                        <span className="item-label">{Dictionary.email}: </span> {packageInfo.reserveFlightInfo.reserver.email}
                      </div>
                      <div>
                        <span className="item-label">{Dictionary.phoneNumber}: </span>{' '}
                        <span dir="ltr">{packageInfo.reserveFlightInfo.reserver.phoneNumber}</span>
                      </div>
                    </div>
                  </div>
                  <div className={'passengerInformation margin-bottom'}>
                    <h5 className="normal-title">{Dictionary.price}</h5>
                    <div className="responsive-table-holder">
                      <div className="itours-responsive-table medium-table bg-white responsive-bg-transparent">
                        <div className="table-row-group">
                          <div className="table-row table-head">
                            <div>{Dictionary.row}</div>
                            <div>{Dictionary.firstName}</div>
                            <div>{Dictionary.lastName}</div>
                            <div>{Dictionary.baseFare}</div>
                            <div>{Dictionary.taxes}</div>
                            <div>{Dictionary.fare}</div>
                          </div>
                          {this.state.farePrice &&
                            this.state.farePrice.map((itemPass, indexPass) => {
                              return (
                                <div className="table-row" key={indexPass}>
                                  <div>
                                    <span className="responsive-label">{Dictionary.row}</span>
                                    <span> {indexPass + 1}</span>
                                  </div>
                                  <div>
                                    <span className="responsive-label">{Dictionary.firstName}</span>
                                    <span>{itemPass.firstName}</span>
                                  </div>
                                  <div>
                                    <span className="responsive-label">{Dictionary.lastName}</span>
                                    <span>{itemPass.lastName} </span>
                                  </div>
                                  <div>
                                    <span className="responsive-label">{Dictionary.baseFare}</span>
                                    <span className="nowrap">
                                      <Price currency={itemPass.currency}>{itemPass.baseFare}</Price>{' '}
                                    </span>
                                  </div>
                                  <div>
                                    <span className="responsive-label">{Dictionary.taxes}</span>
                                    <span className="nowrap">
                                      <Price currency={itemPass.currency}>{itemPass.totalTaxes}</Price>{' '}
                                    </span>
                                  </div>
                                  <div>
                                    <span className="responsive-label">{Dictionary.fare}</span>
                                    <span className="nowrap">
                                      <Price currency={itemPass.currency}>{itemPass.totalFare}</Price>
                                    </span>
                                  </div>
                                </div>
                              );
                            })}
                          <div className="table-row total-row">
                            <div>
                              <b>{Dictionary.baseFare}</b>
                            </div>
                            <div />
                            <div />
                            <div />
                            <div />
                            <div>
                              {' '}
                              <b>
                                {' '}
                                <Price currency={packageInfo.currency}>{packageInfo.reserveFlightInfo.flightFare.baseFare}</Price>{' '}
                              </b>{' '}
                            </div>
                          </div>
                          <div className="table-row total-row">
                            <div>
                              <b>{Dictionary.totalTaxes}</b>
                            </div>
                            <div />
                            <div />
                            <div />
                            <div />
                            <div>
                              {' '}
                              <b>
                                {' '}
                                <Price currency={packageInfo.currency}>{packageInfo.reserveFlightInfo.flightFare.totalTaxes}</Price>{' '}
                              </b>{' '}
                            </div>
                          </div>
                          <div className="table-row total-row">
                            <div>
                              <b className="purple">{Dictionary.totalFare}</b>
                            </div>
                            <div />
                            <div />
                            <div />
                            <div />
                            <div>
                              <b className="purple">
                                <Price currency={packageInfo.currency}>{packageInfo.reserveFlightInfo.flightFare.totalFare}</Price>
                              </b>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}

                {!!domesticHotelInfo && domesticHotelReserveInfo && (
                  <>
                    <div className="card-padding bg-white box-border margin-bottom">
                      <div className="clearfix margin-bottom-half">
                        <div className={`${domesticHotelReserveInfo.status === 'Issued' ? "pull-start" : ""}`}>
                          <h3 className="normal-title">
                            <div>
                              {voucherStatusIconClass && <span className={`itours-icon icon-xlarge margin-end-half ${voucherStatusIconClass}`} />}
                              <span>{voucherStatusMessage}</span>
                            </div>
                          </h3>
                          {this.props.LAN === 'IR' && (
                            domesticHotelReserveInfo.status === Payment.WebServiceCancel ||
                            domesticHotelReserveInfo.status === Payment.WebServiceUnsuccessful ||
                            domesticHotelReserveInfo.status === Payment.PriceChange ||
                            domesticHotelReserveInfo.status === Payment.Unavailable
                          ) && <div className="font-18 semi-bold pair-row purple">
                              <p className="no-margin-bottom ">جهت بررسی وضعیت رزرو خود، حتما با پشتیبانی آیتورز تماس بگیرید.</p>
                              <span dir="ltr">(+98) 21 91008091 </span>
                            </div>}
                          <div className="margin-bottom">
                            <span className="inline-block">{Dictionary.trackingCode}:</span>
                            <span className="inline-block margin-end"> {domesticHotelReserveInfo.id} </span>
                          </div>
                        </div>

                        {domesticHotelReserveInfo.status === 'Issued' && (
                          <a
                            href={
                              `https://voucher.itours.ir/en/${process.env.REACT_APP_SERVER_TENANTID}/hoteldomestic/voucher?reserveId=${domesticHotelReserveInfo.id}&username=${domesticHotelReserveInfo.reserver.userName}`
                            }
                            download
                            target="_blank"
                            rel="noopener noreferrer"
                            className="button color-1-btn pull-end">
                            {Dictionary.GetVoucher}
                          </a>
                        )}
                      </div>
                      <h3 className="normal-title">{Dictionary.ReserverInformartion}</h3>
                      <div>
                        <div className="margin-bottom-light">
                          <b className="inline-block margin-end-light">{Dictionary.fullName}:</b>
                          <span className="inline-block">
                            {' '}
                            {domesticHotelReserveInfo.reserver.firstName} {domesticHotelReserveInfo.reserver.lastName}
                          </span>
                        </div>
                        <div className="margin-bottom-light">
                          <b className="inline-block margin-end-light">{domesticHotelReserveInfo.reserver.username?.invlude("@") ? Dictionary.email : Dictionary.userName}:</b>
                          <span className="en-font inline-block" dir="ltr">
                            {' '}
                            {domesticHotelReserveInfo.reserver.userName}
                          </span>
                        </div>
                        <div>
                          <b className="inline-block margin-end-light">{Dictionary.mobileNumber}:</b>
                          <span className="inline-block" dir="ltr">
                            {' '}
                            {domesticHotelReserveInfo.reserver.phoneNumber}
                          </span>
                        </div>
                      </div>
                    </div>
                  </>
                )}

              {
                paymentArray && paymentArray.length > 0 ? (
                  <div>
                    <h3>{Dictionary.peymentDetails}</h3>
                    <div className="itours-responsive-table large-table text-center">
                      <div className="table-row-group">
                        <div className="table-row table-head">
                          <div>{Dictionary.row}</div>
                          <div>{Dictionary.port} </div>
                          <div>{Dictionary.trackingNumber} </div>
                          <div>{Dictionary.paymentDate} </div>
                          <div>{Dictionary.paymentTime} </div>
                          <div>{Dictionary.missionary} </div>
                          <div>{Dictionary.status} </div>
                        </div>
                        {paymentArray &&
                          paymentArray.map((item, index) => {
                            return (
                              <div className="table-row bg-white" key={index}>
                                <div>
                                  <span className="responsive-label">{Dictionary.row}</span>
                                  <span>{index + 1}</span>
                                </div>
                                <div>
                                  <span className="responsive-label">{Dictionary.port}</span>
                                  <span>{item.gatewayName}</span>
                                </div>
                                <div>
                                  <span className="responsive-label">{Dictionary.trackingNumber} </span>
                                  <span>{item.id}</span>
                                </div>
                                <div>
                                  <span className="responsive-label">{Dictionary.paymentDate} </span>
                                  <span className="nowrap">{item.transferDateTime.split('T')[0]}</span>
                                </div>
                                <div>
                                  <span className="responsive-label">{Dictionary.paymentTime}</span>
                                  <span className="nowrap">{item.transferDateTime.split('T')[1]}</span>
                                </div>
                                <div>
                                  <span className="responsive-label">{Dictionary.missionary}</span>
                                  <span dir="ltr" className="nowrap">
                                    <Price currency={this.props.currencyCode}>{item.amount}</Price>
                                  </span>
                                </div>
                                <div>
                                  <span className="responsive-label">{Dictionary.status}</span>
                                  <span>{item.isConfirmed ? Dictionary.paid : Dictionary.unpaid}</span>
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  </div>
                ) : null
                // <div>{Dictionary.paymentDataIsLoading}</div>
              }
              <div className="card-padding bg-white box-border clearfix">
                <span className="inline-block">
                  {LayoutConfig.themeClassName === 'naminTravel-theme'?
                    Dictionary.ForQuestionsRegardingThisReservationPleaseContactNaminTravelDirectly
                    :LayoutConfig.themeClassName === 'ayanair-theme'?
                    Dictionary.ForQuestionsRegardingThisReservationPleaseContactAyanairDirectly
                    :Dictionary.ForQuestionsRegardingThisReservationPleaseContactItoursDirectly
                  }
                  :
                </span>
                {LayoutConfig.contacts && LayoutConfig.contacts.telephone && LayoutConfig.contacts.telephone.length > 0
                  ? this.phoneItem(LayoutConfig.contacts.telephone[0])
                  : this.phoneItem(Dictionary.supportNumber)}
              </div>
            </>
            )}
            aside={(
              <>
              {flightInfo && (
                <FlighSummary
                  showFlightAllDetails
                  model={this.props.flightInfo.airItinerary.originDestinationOptions}
                  itinTotalFare={this.props.flightInfo.flightFare}
                  fareInfo={this.props.flightInfo.passengersFare}
                  totalPrice={this.props.flightInfo.flightFare.totalFare}
                  currencyCode={this.props.flightInfo.currency}
                  showButton={false}
                  feild={'fare'}
                />
              )}
              {voucherDetails && (
                <SummaryHotel
                  model={voucherDetails}
                  currencyCode={this.props.voucherDetails.currency?.code}
                  showButton={false}
                  totalPrice={totalPrice}
                  night={night}
                />
              )}

              {packageInfo && (
                <PackageSummary
                  currencyCode={packageInfo.currency}
                  flightModel={packageInfo.reserveFlightInfo.airItinerary.originDestinationOptions}
                  hotelModel={packageInfo.reserveHotelInfo}
                  night={packageInfo.reserveHotelInfo.date.duration}
                  cancellationPolicy={packageInfo.reserveHotelInfo.cancellationPolicy}
                  flightPrice={packageInfo.reserveFlightInfo.flightFare.totalFare}
                />
              )}

              {!!domesticHotelInfo && domesticHotelReserveInfo && (
                <DomesticHotelSummery 
                hotelInformation={hotelInformation}
                reserveInformation={reserveInformation}
                currencyCode={this.props.currencyCode}
                //safarmarketId={safarmarketId}
                //cancellationPolicy={hotelInformation.cancellationPolicy}                            
                />
              )}                      

              {flightInfo && flightInfo.reserveStatus === Payment.Pending && (
                <div className="box-border top-color-border orange margin-top bg-white">
                  <div className="colored">{Dictionary.pricesToRiseSoon}!</div>
                  <div className="font-12">{Dictionary.bookNowToGetTheBestRateForYourCheckinDate}.</div>
                </div>
              )}
            </>
            )}
          />
        </div>
      </div>
    );
  }
}
//Map
const mapStateToProps = (state) => ({
  voucherDetails: state.HotelSearch.selectedHotel,
  currencyCode: state.UserData.Currency,
  paymentInformation: state.PaymentData.voucherDetailsPayment,
  flightInfo: state.FlightSearch.flightSelected,
  respond: state.VisualData.Responded,
  flightError: state.FlightSearch.flighterror,
  Dictionary: state.VisualData.Dictionary,
  LAN: state.VisualData.LAN,
  packageInfo: state.PackageSearch.reservedPackageData,
  Verification: state.VisualData.Verification,
  domesticHotelReserveInfo: state.DomesticHotel.resrevedDomesticHotel,
  domesticHotelInfo: state.DomesticHotel.domesticHotelByIdInfo
});
const mapDispatchToProps = (dispatch) => ({
  GetReserveById: (param) => {
    dispatch(GetReserveById(param));
  },
  gatewayReport: (param) => {
    dispatch(gatewayReport(param));
  },
  editMessage: (params) => {
    dispatch(editMessage(params));
  },
  setFlightSelected: (params) => {
    dispatch(setFlightSelected(params));
  },
  selectedHotel: (params) => {
    dispatch(selectedHotel(params));
  },
  showAccountWindowIR: x => dispatch(showAccountWindowIR(x))
});
export default connect(mapStateToProps, mapDispatchToProps)(ReserveDetails);
