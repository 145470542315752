import React from "react";
import { connect } from "react-redux";
import axios from "axios";
import { ServerAddress, Header,User } from "../../Enum/Urls";
import { CountryCode } from "../../Enum/Models";
import {Form,Input,Select} from "antd";
import LayoutConfig from "../../Config/layoutConfig";
import Map from "../../components/Common/Map";

class ContactUS extends React.PureComponent {
  state={
    submitLoading:false,
    submitMessage:undefined,
    showModal:false
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  closeModal = () =>{
    if (!this.state.submitLoading){
      this.setState({showModal:false});
    }
  }
  handleReset = () => {
    this.props.form.resetFields();
  };
  handleSubmitContact = e =>{
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.setState({submitLoading:true,showModal:true});
        axios.post(`${ServerAddress.Type}${ServerAddress.User}${User.CreateContactUs}`, values, {headers: {...Header,},})
        .then(({ data }) => {
          if (data.error) {
             this.setState({
                submitLoading:false,
                submitMessage:{text:data.error.message,success:data.success}
              });
            }else{
              this.handleReset();
              this.setState({
                submitLoading:false,
                submitMessage:{text:this.props.Dictionary.yourMessageHasBeenSentSuccessfully,success:data.success}
              });
           }
        })
        .catch((error) => {
          try {
            this.setState({
              submitLoading:false,
              submitMessage:{text:error.response.data.error.message,success:false}
            });
          } catch (e) {
            this.setState({
              submitLoading:false,
              submitMessage:{text:"Internal Error,Please refresh the page",success:false}
            });
          }
        });
      }
    });
  }
  render() {
    const { Dictionary, LAN } = this.props;
    const { getFieldDecorator } = this.props.form;
    const { TextArea } = Input;
    const { Option } = Select;
    const phoneItem = (value)=>{
      if (LayoutConfig.themeClassName === 'naminTravel-theme'){
        return (
          <a href={`https://wa.me/${value.replace(/ +/g, "").replace(/[{(+)}]/g, '').replace(/-/g , "")}`} className="contact-row content-link" rel="noopener noreferrer" target="_blank">
          <span className="margin-end-half itours-icon whatsapp icon-small" />
            <span dir="ltr">{value} ({Dictionary.WhatsAppOnly})</span>
          </a>
        );
      }
      return (
        <div className="contact-row">
          <span className="margin-end-half itours-icon red-phon-icon icon-small mirror-rtl" />
          <span dir="ltr">{value} </span>
        </div>
      )
    };

    let map = null;

    if (process.env.REACT_APP_THEME ==='AYANAIRCOM'){
      map = (<Map
        zoom={14}
        hotels={[{ latitude: 36.19004, longitude: 43.9743052 }]}
      />)
    } else if (process.env.REACT_APP_THEME ==='ITOURS_IR'){
      map = (<Map
        zoom={14}
        hotels={[{ latitude: 35.729618, longitude: 51.415335 }]}
      />)
  }
    return (
      <div className="section-vertical-padding bg-white contact-us">
        <div
          className={`itours-modal ${this.state.showModal ? "itours-modal visible" : ""}`}
        >
          <div className="modal-overlay" onClick={this.closeModal} />
          <div className="modal-inner small-width text-center">
            <div className="modal-main">
              {this.state.submitLoading ? (
                <div className="loading-outer-container">
                  <div>
                    <div className="loading-inner-container">
                      <div className="item-1"></div>
                      <div className="item-2"></div>
                      <div className="item-3"></div>
                      <div className="item-4"></div>
                      <div className="item-5"></div>
                    </div>
                    <div className="text-center">
                      {Dictionary.loadingData} ...
                    </div>
                  </div>
                </div>
              ) : (
                this.state.submitMessage && 
                <div>
                  <br/><br/>
                    {this.state.submitMessage.success?
                    <span className="itours-icon check-green icon-xlarge" />
                    :
                    <span className="itours-icon error-icon icon-xlarge" />
                    }
                  <div className="margin-top margin-bottom">
                  {this.state.submitMessage.text}.
                  </div>
                  <br/>
                </div>
              )}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="button color-1-btn"
                onClick={this.closeModal}
                disabled={this.state.submitLoading}
              >
                {Dictionary.ok}
              </button>
            </div>
          </div>
        </div>
        <div className="page-container">
          {process.env.REACT_APP_THEME !=='AYANAIRCOM' && <h2 className="page-title"> {Dictionary.contactUs} </h2>}
          <div className="float-row large-gap">
            <div className="col-small-12 col-medium-7">
              
              {process.env.REACT_APP_THEME ==='AYANAIRCOM' && <h2 className="page-title"> {Dictionary.contactUs} </h2>}
              
              <div className="margin-bottom">
                
                {process.env.REACT_APP_THEME !=='AYANAIRCOM' && (
                  <p>{Dictionary.contactP1}</p>
                )}
                
                {process.env.REACT_APP_SERVER_LOCALSERVER === 'itours.ir'?<div>
                  <span className="margin-end-half itours-icon icon-small red-phon-icon mirror-rtl" />
                  {/* پشتیبانی 24 ساعته (از 8 صبح تا 6 صبح روز بعد) : <span dir="ltr" className="no-wrap">+98 21 91008091</span>     */}
                  پشتیبانی همه روزه از ساعت 9 الی 23 : <span dir="ltr" className="no-wrap">+98 21 91008091</span>         
                </div>

                :
                LayoutConfig.contacts && LayoutConfig.contacts.telephone && (LayoutConfig.contacts.telephone.length > 0) 
                  ? <>
                    {LayoutConfig.themeClassName === 'naminTravel-theme' && <a href="tel:+4722112744" className="contact-row content-link">
                      <span className="margin-end-half itours-icon red-phon-icon icon-small mirror-rtl" />
                      <span dir="ltr">0047 22112744 (Head Office) </span>
                    </a>}
                    {LayoutConfig.contacts.telephone.map(phone=><div key={phone}>{phoneItem(phone)}</div>)}
                  </>
                  :
                  phoneItem(LAN === "IR"? "(+98) 21 91008091": LAN === "AR"? "..." : LAN === "TR"? "...": "(+47)22112744")
                }
                <div className="contact-row">
                  <span className="margin-end-half itours-icon red-email-icon icon-small" />
                  {process.env.REACT_APP_SERVER_LOCALSERVER === 'itours.ir'?<span>
                    شما همچنین می توانید برای ارتباط سریع تر از طریق آدرس ایمیل 
                    <a href="mailto:online@itours.ir" target="_blank" rel="noreferrer" className="en-font content-link margin-start-half margin-end-half" >Online@itours.ir</a>
                      با ما در ارتباط باشید
                  </span>
                  :
                  <a
                    href={`mailto:${
                      LayoutConfig.contacts && LayoutConfig.contacts.email
                        ? LayoutConfig.contacts.email
                        : LAN === "IR"
                        ? "online@itours.ir"
                        : LAN ==="AR"
                        ? "..."
                        : LAN ==="TR"
                        ? "..."
                        : "info@itours.no"
                    }`}
                    dir="ltr"
                    className="en-font content-link"
                  >
                    {LayoutConfig.contacts && LayoutConfig.contacts.email
                      ? LayoutConfig.contacts.email
                      : LAN === "IR"
                      ? "online@itours.ir"
                      : LAN === "AR"
                      ? "..."
                      : LAN === "TR"
                      ? "..."
                      : "info@itours.no"}
                  </a>}
                </div>
                <div className="contact-row">
                  <span className="margin-end-half itours-icon red-address-icon icon-small" />
                  <span>
                    {LayoutConfig.contacts && LayoutConfig.contacts.address
                      ? LayoutConfig.contacts.address
                      : LAN === "IR"
                      ? "تهران ، خیابان بهشتی ، خ وزرا پلاک ۲۷ ، واحد ۲۲"
                      : LAN ==="NO"
                      ? "Torgata 31 Oslo, 0183 Norway"
                      : LAN === "AR"
                      ? "..."
                      : LAN === "TR"
                      ? "..."
                      : "(0183) - Torggata 31"}
                  </span>
                </div>
              </div>

              {map}

            </div>
            <div className="col-small-12 col-medium-5">
              
              {process.env.REACT_APP_THEME ==='AYANAIRCOM' && <h2 className="page-title"> {Dictionary.sendMessage} </h2>}
              
              <div className="card margin-bottom">
                <Form onSubmit={this.handleSubmitContact}>

                <Form.Item label={Dictionary.fullName} className="form-group">
                    {getFieldDecorator('name', {
                      rules: [{ required: true, message: Dictionary.thisFieldIsRequired }],
                    })(
                      <Input
                        placeholder={Dictionary.fullName}
                      />,
                    )}
                  </Form.Item>
                  <Form.Item label={Dictionary.email} className="form-group">
                    {getFieldDecorator('email', {
                      rules: [
                        { required: true, message: Dictionary.thisFieldIsRequired },
                        { type:"email", message: Dictionary.enteredEmailAddressIsNotValid2 }
                      ],
                    })(
                      <Input
                        placeholder={Dictionary.email}
                      />,
                    )}
                  </Form.Item>
                  <Form.Item label={Dictionary.phoneNumber} className="form-group">
                    {getFieldDecorator('phoneNo', {
                      rules: [{ required: true, message: Dictionary.thisFieldIsRequired },
                        {pattern:"^[0-9]*$",message:Dictionary.onlyNumbersAreAllowed}]
                      })(
                      <Input
                        maxLength={15}
                        placeholder={Dictionary.phoneNumber}
                      />,
                    )}
                  </Form.Item>
                  <Form.Item label={Dictionary.Country} className="form-group">
                    {getFieldDecorator('country', {
                      rules: [{ required: true, message: Dictionary.thisFieldIsRequired }],
                    })(
                      <Select
                        showSearch
                        placeholder={Dictionary.Country}
                        dir="ltr"
                      >
                      {CountryCode.map((item) => (
                        <Option key={item.value} value={item.value}>{item.code}</Option>
                      ))}
                    </Select>,
                    )}
                  </Form.Item>
                  <Form.Item label={Dictionary.messageText} className="form-group">
                    {getFieldDecorator('message', {
                      rules: [{ required: true, message: Dictionary.thisFieldIsRequired }],
                    })(
                      <TextArea rows={4} placeholder={Dictionary.messageText} />,
                    )}
                  </Form.Item>

                  <div className="clearfix">
                    <button type="submit" className="button red-btn pull-end">
                      {Dictionary.send}
                    </button>
                  </div>

                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  Dictionary: state.VisualData.Dictionary,
  LAN: state.VisualData.LAN,
});

const WrappedContactUS = Form.create({ name: 'contactUsForm' })(ContactUS);

export default connect(mapStateToProps)(WrappedContactUS);
