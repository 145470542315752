import React from 'react';

const TermsSe = () => (
  <div className="wrapper">
    <div>Det är köparen och resenärens ansvar att...</div>
    <br />
    <ul>
      <li>Läs och godkänn resevillkoren innan köp.</li>
      <li>
        Se till att all information är korrekt vad gäller stavningar av namn och titel, samt kontrollera flygschemats datum innan avtalet blir effektiv. Genom
        att välja betalningsalternativ bekräftar du informationen.
      </li>
      <li>
        När du har genomfört ditt köp får du biljetten skickad till dig via e-post inom 24 timmar. Det är kundens ansvar att kontrollera att antal biljettnummer
        matchar antalet resenärer. Om resan består av två enkelbiljetter ska varje passagerare ha ett biljettnummer per sätt. Om det saknas biljettnummer eller
        andra oklarheter bör du kontakta Itours inom två dagar.
      </li>
      <li>Kontrollera regelbundet den angivna e-postadressen, eftersom all korrespondens med oss sker via e-post.</li>
      <li>Kontinuerlig kontroll av flygtidtabeller och eventuella ändringar i tidtabellen för både ut- och returresa.</li>
      <li>Kontrollerar aktuella incheckningstider för hela resan.</li>
      <li>Kontrollera med respektive flygbolag från vilken flygplatsterminal avgång sker.</li>
      <li>
        Kontrollera visumkraven för deras slutdestination samt eventuella mellanlandningar. Detta görs via respektive ambassad och flygbolagen du reser med.
      </li>
      <li>
        Kontrollera passbestämmelserna och passets giltighet, eftersom vissa länder kräver att ditt pass är giltigt i minst sex månader efter hemkomsten resa
      </li>
      <li>Kontrollera vilka vaccinationer som behövs innan du reser.</li>
    </ul>
    <div>Ordning.</div>
    <p>
      Ange korrekt e-postadress vid bokning. All kommunikation mellan Itours och resenärerna efter bokning sker via e-post. Därför är det viktigt att
      kontinuerligt kontrollera tillgänglig e-post mellan bokningstillfället och avresedatum.
    </p>
    <p>
      Vid beställning av biljetterna tur och retur måste alla delar av biljetten användas i rätt ordning. Om resenären misslyckas med att delta i någon sträcka,
      medvetet eller omedvetet kommer flygbolaget att ställa in den återstående delen av resan och kommer inte att göra återbetalningen för de delar av resan
      som inte används.
    </p>
    <p>
      <b>
        Itours erbjuder även turné- och returbiljetter som består av två enkelbiljetter. Reglerna för varje biljett gäller. Det kommer fram när biljetten består
        av två enkelresor. I de fall beställningen består av två enkelflyg är kunden ansvarig för att kontrollera rese- och biljettnummer för båda resorna tas
        emot.
      </b>
    </p>
    <p>
      När du har beställt och betalat resa skickar vi en orderbekräftelse till den e-postadress som angavs vid bokningstillfället. I detta bekräftelsemail du
      hittar en länk till din personliga bokningssida där du kan skriva ut din e-biljett.
    </p>
    <p>
      Om flygbolaget har ändrat flygschemat kan du se detta här. Vi rekommenderar att du skriver ut biljetten från denna sida innan du reser. De informationen
      på sidan är på engelska. För att ändra eller lägga till kontaktuppgifter i din beställning behöver du ha ett bokningsnummer när du kontaktar oss. Om du
      inte har fått bekräftelse via mail är det viktigt att du kontaktar oss omgående så att vi kan bekräfta detta och korrigera e-postadress om det är inte
      korrekt. Du reser alltid med e-biljett (elektronisk biljett). Det finns inte längre pappersbiljetter, istället får du ett elektroniskt resedokument via
      e-post från oss. Det enklaste är att du skriver ut e-biljetten och tar med dig till incheckningen. Där hittar du all viktig information, biljettnummer och
      flygtider. Du kommer att få ett boardingkort vid incheckningsdisken på flygplatsen när du visar upp ett giltigt pass.
    </p>
    <div>
      <b>Kontrollera att alla resenärer har fått biljettnummer.</b>
    </div>
    <p>
      Boardingkort kan skrivas ut hemma om du checkar in online. De kan annars hämtas vid checkdisken på flygplatsen. För att boka en resa på hemsida du måste
      vara över 18 år eller ha tillstånd från en vårdnadshavare
    </p>
    <div>
      <b>namn.</b>
    </div>
    <div>
      <b>Varning.</b>
    </div>
    <p>På grund av flygbolagens höga krav på säkerhet är det mycket viktigt att fylla i korrekt stavade namn på alla resenärer i din bokning.</p>
    <p>Först: För att ange förnamnet som det står i passet</p>

    <p>
      Efternamn: Ange alla efternamn på basis av passet. Du kan inte förkorta ditt namn eller bara förse dem med initialer. Om du ska gifta dig, du måste boka
      din resa i samma namn som du ska ha i passet under resan. Det är köparens ansvar att kontrollera så att alla namnen är rättstavade. Observera att
      bokningssystemet tar bort allt mellan streck och skiljetecken mellan namn. Åh, æ och ø ändrades enligt internationella alfabetet När
      betalningsalternativet har valts och biljetten är utfärdad är det upp till flygbolagets regler om namnbyte är tillåten. Det är kundens ansvar att
      informera sig om flygbolagens regler före genomfört köp. I de fall flygbolaget gör det byte / korrigering av namn, tar Itours en avgift på 500 NOK /
      biljett för att hantera ändringar, utöver den eventuella avgiften från flygbolaget.
    </p>
    <div>
      <strong>Reser med barn.</strong>
    </div>
    <p>
      Det är viktigt att ange rätt ålder på alla barn som följer med oss på vår resa. Deras ålder kontrolleras av flygbolaget. Observera att barnets ålder vid
      återlämningsdatum kommer att gälla. Om ditt barn fyller två år under resan ska resan bokas som barn över två år. Detsamma gäller om resenär fyller tolv år
      på resan. Denna ska sedan beställas som vuxen.
    </p>
    <p>Barn som inte har fyllt två år registreras som spädbarn. Det gör att barnet inte har en separat sittplats och istället får sitta i knät på en vuxen.</p>
    <p>
      Det går inte att boka en resa till ett ännu inte fött barn, eller en person som du inte känner till namnet på. Så du kan inte lägga en beställning på vår
      hemsida och ange , "Baby" det namnet, eftersom det korrekta namnet måste anges vid beställning och detta måste matcha namnet på ditt pass. Det kan vi inte
      garantera barn är inte med från början för att få plats på planet. Det är inte alltid tekniskt möjligt att boka ytterligare plats för ett spädbarn på en
      befintlig reservation. Ett spädbarn kan inte läggas till i en delvis använd beställning. Till exempel om föräldrarna har rest till ett annat land för att
      föda barn, och har en t/r-biljett är det inte möjligt att lägga till spädbarnet på returen. Det är inte heller möjligt att placera ett spädbarn ensamt i
      en separat beställning. I ett sådant fall måste det beställas en ny enkelbiljett för en vuxen och spädbarn.
    </p>
    <div>
      <b>Barn som reser ensamma.</b>
    </div>
    <p>
      På vår hemsida är det inte möjligt att boka en separat barnbiljett eftersom barnrabatt endast gäller i vuxens sällskap. Om du har bokat till resa för ett
      barn på vuxenbiljett är det tekniskt inte möjligt att boka assistans ombord. För barn som reser på egen hand är det ofta obligatorisk förbokning kallas
      Ensamkommande Minor Service. Reglerna och kostnaden för detta varierar från flygbolag till flygbolag. Minsta och högsta tillåtna ålder varierar också. SAS
      minimiålder är till exempel fem år, medan Air France har en minimiålder på 4 år. Generellt bör du använda samma flygbolag på resan för att garantera att
      den fungerar. Vi behöver veta ditt namn, adress och telefonnummer till de personer som levererar/hämtar barnet på flygplatsen. Ett så kallat Action Advice
      ska också fyllas i på flygplatsen. Varje ensamkommande mindre tjänst måste skickas som en begäran från Itours till flygbolaget och denna tjänst garanteras
      inte förrän flygbolaget har accepterat begäran. Om du vill boka barnbiljett med hjälp för ensamkommande barn vänligen kontakta oss via kontaktformuläret
      på vår hemsida. Observera att en bokningsavgift tillkommer (295 NOK).
    </p>
    <div>
      <b>Betalning.</b>
    </div>
    <p>
      Du har flera alternativ. För att betala med kreditkort eller via nätbank betalar du hela summan samma dag som beställningen genomförs. Observera att alla
      former av betalning är bindande när du har valt betalningssätt. När du betalar med kort tillkommer en extra avgift. Detta kommer vi att meddela dig innan
      avtalet träder i kraft och avgiften återbetalas inte. Kortet ska vara utfärdat i Skandinavien Avbeställning gäller inte för transport. När kund har
      genomfört beställningen anses vara betald och efter detta är bokningen bindande.
    </p>
    <p>
      Itours förmedlar produkter från olika leverantörer, och ansvarar därför inte för de händelser som har att göra med slutförandet av resan. Exempel på detta
      är förlorat bagage, bagage som inte ingår i flygningen, inställda flyg och ändringar i flygtidtabeller. Detta ansvar vilar med flygbolagen och kunden bör
      därför vända sig direkt till det aktuella flygbolaget med eventuella klagomål. Förlorat eller skadat bagage måste alltid vara det rapporteras direkt i
      stället för det relevanta flygbolaget.
    </p>
    <div>
      <b>Tekniska problem.</b>
    </div>
    <p>
      Itours reserverar sig för eventuella tekniska problem, datafel, prissättningsfel och ändringar som görs av flygbolag utanför vår kontroll. Det kan t.ex.
      vara flygbolag som visar platser som inte är tillgängliga, ändringar i flygtidtabeller eller felaktiga priser på grund av datafel. Flygbolagen har också
      rätt till ändra beställningen inom 24 timmar efter att beställningen har slutförts. Vi förbehåller oss rätten att kontakta dig inom 24 timmar på vardagar
      för eventuella ändringar i ordningen. Om problem uppstår under eller i samband med helgen kontaktar vi dig först kommande vardag.
    </p>
    <div>
      <b>Dra tillbaka.</b>
    </div>
    <p>
      Avbeställning ska inte gälla för transport, enligt gällande lag. Vi har ingen 24-timmarsregel när det kommer till våra beställningar. När betalningen är
      klar och biljetten är utfärdad har vi inga möjligheter att ändra din biljett utan måste följa flygbolagens återutgivna-/avbokningspolicy. Dessa regler är
      bestäms av flygbolagen och inte av oss som agent. Läs mer under Avbokning/ombokning.
    </p>
    <div>
      <b>Dubbel bokning.</b>
    </div>
    <p>
      Resenären har ansvar för att se till att eventuella dubbelbokningar annulleras oavsett hur de genomförs. Resenären riskerar annars att flygbolaget utan
      förvarning avbokar dubbelbokning. Itours tar inget ansvar för flygbolagsinställda eller uteblivna återbetalningar i samband därtill.
    </p>
    <div>
      <b>Avbokning/ombokning.</b>
    </div>
    <p>
      När betalningen är genomförd och biljetten är utfärdad är det flygbolagets regler för ombokning/avbokning som kommer att gälla. Det är kundens ansvar för
      att få information om vilka regler som gäller på biljetten innan köpet genomförs. Detta kan göras genom att kontakta kundtjänst på +47 22 11 27 44 måndag
      - fredag ​​kl. 10.00 till 16.00. I dessa fall tillåter flygbolaget ombokning. Itours tar 500 NOK/biljetthanteringsavgift utöver avgift för återutgivna
      flygbolag. I de fall flygbolaget ger återbetalning på inställda biljetter är alltid till en kostnad. Förutom flygbolagets avbokningsavgift Itours kostar
      500 kr/biljett. Energiförbrukningskostnader som kortavgift, avbeställningsförsäkring och serviceavgift återbetalas. I de flesta fall återbetalas endast en
      liten del av skatten, eftersom dessa inkluderar bränslekostnader och andra avgifter som flygbolaget kommer att ersätta. Vid återbetalning av de enda
      flygplatsskatterna tar Itours en administrationsavgift på 250 NOK / biljett till de skatter och avgifter som flygbolaget kommer att ersätta.
      Återbetalningen utförs av flygbolagen; handläggningstiden är 5-12 veckor.
    </p>
    <p>
      Ombokning måste göras minst 24 timmar före avgång vid antingen den ursprungliga bokningen eller ombokningen beroende på vad som inträffar först.
      Ombokningen är inte klar innan Återutgiven avgift betalas och Itours har utfärdat ny biljett. All ombokning ska göras per telefon. Vänligen ha ditt kort
      tillgängligt för betalning av ombokning när du ringer oss. Avbokning måste göras senast 24 timmar innan den ursprungliga beställningens avgång. Resan
      övervägs avbokas först när Itours har bekräftat avbokningen. Itours återbetalar aldrig delvis använd biljett.
    </p>
    <div>
      <b>Flygtider.</b>
    </div>
    <p>
      Alla angivna tider är i lokal tid för respektive flygplats. De tider som anges vid mellanlandning godkänd tidpunkt för byte av flygplan, uppskattad av
      flygbolagen i samarbete med flygplatsen. I händelse av försening innebär att försäkringstagare inte gör nästa flyg på en biljett som täcker fler flyg, är
      det flygbolagets ansvar att hjälpa kunden till destinationen. Vid bokning av separata biljetter har flygbolaget inget ansvar för om de missar nästa flyg
      på grund av förseningar. Det är sedan kundens ansvar att se till att det finns tillräckligt med tid för att byta plan. Eventuella märkestransporter och
      logi under resan ingår inte i biljettpriset. Det är resenärens ansvar att kontrollera flygtider innan beställning.
    </p>
    <div>
      <b>Direkt eller non-stop.</b>
    </div>
    <p>
      Att resa med direktflyg kan innebära att planet kan landa på vägen till exempel för att tanka så länge flygplanet inte byter flygning antal vid landning
      anser flygbolagen att det finns ett direktflyg. Vi markerar denna mellanlandning på bokningsbekräftelsen och gör inga försök sätt att dölja eventuella
      mellanlandningar. Denna typ av tekniskt stopp är godkänd baserat på de internationella flygreglerna som antagits av IATA (International Air Traffic).
      Förening). Nonstop-flight är ett flyg som inte mellanlandar utan flyger från punkt A till B utan exempel för att tanka. Flyg indirekta flyg är med en
      eller flera mellanlandningar och där du byter flygplan och flygnummer. Byte av flygplats och transfer
    </p>
    <p>
      Om en resa bokad indirekt som inkluderar byte av flygplats ska kund själv stå för transferkostnaderna mellan flygplatserna. Detta gäller för till exempel
      vid en mellanlandning i London då resan i vissa fall går till London/Heathrow och sedan vidare från London/Gatwick. Andra vanliga platser där det ibland
      inträffar byta flygplats vid en mellanlandning till exempel. New York och Paris. Kom ihåg att kontrollera ditt flygschema noggrant. Vid byte
      flygplatsbagaget måste tas ut och sedan checkas in igen på den nya flygplatsen. Vid resa av ett stopp kräver en övernattning eftersom nästa flyget
      fortsätter inte förrän nästa dag, resenären måste till och med betala för valfri hotellnatt.
    </p>
    <div>
      <b>Ändringar i flygtidtabeller.</b>
    </div>
    <p>
      Flygbolagen kan med kort varsel ändra flygtider och ställa in flyget. Detta ligger utanför Itours kontroll. , Ändringen av flygtiden före avgång kommer
      att e-postas med aktuell information till den e-postadress som anges i beställningen. Om ändringen av flygtiden inte accepteras av resenären måste
      rapporteras till Itours omgående. Om förändringen sker efter att ha lämnat är det svårt för Itours att meddela. Det är resenärens ansvar att hålla dig
      informerad om korrekta flygtider för ut- och hemresa. Dessa tider flygbolaget återbetalning på grund av ändringar i flygtidtabeller Itours tar ut en
      administrativ avgift på 150 NOK/biljett. Återbetalning sker från flygbolagen och handläggningstiden är från 5 till 12 veckor.
    </p>
    <p>
      <b>
        Om du har bokat en tur- och returresa som består av två enkelresor och förändring sker i flygtid eller ett flyg ställs in på en av resorna är det inte
        tillåtet att ändra eller avbryta den andra resan på grund av detta, eftersom resor i Carriers ögon inte hänger ihop.
      </b>
    </p>
    <p>
      Itours kan inte hållas ansvarigt för om på eller i närheten av destinationen inträffar miljökatastrof, krigshandling, strejk eller annan ingripande
      händelse som gör att den planerade resan inte kan genomföras. Itours ordnar biljetter och kan inte hållas ansvarigt för eventuella ändringar i
      flygtidtabeller eller inställda flyg.
    </p>
    <div>
      <b>Försäkring.</b>
    </div>
    <p>
      Vi rekommenderar starkt våra resenärer att ha en reseförsäkring eller köpa en innan avresa. Det är resenärernas ansvar att ha den försäkring som behövs
      för sin resa.
    </p>
    <div>
      <b>Dokumentation.</b>
    </div>
    <p>
      Resenärens ansvar att kontrollera eventuella visumkrav med ambassaden. Beroende på destinationen vägrade du gå ombord om du inte har giltiga
      resehandlingar, såsom pass, visum och transitvisum (tänk också på att vissa länder behöver ett pass som är giltigt i minst 6 månader efter
      utskrivningsdatum). Vi rekommenderar att du alltid har ditt pass när du ska ut och resa. Européer som reser till USA eller mellan de landar som måste
      registreras inträde online. Passagerare uppmanas att gå in på estas webbsida och fylla i den obligatoriska informationen minst 72 timmar Före avgång.
      Itours kan hjälpa till med visum till Australien för vissa nationaliteter, kostar 500 Nok/person.
    </p>
    <div>
      <b>Checka in.</b>
    </div>
    <p>
      Vi rekommenderar att du är på flygplatsen i god tid före avgång. Tänk på att det kan vara långa köer för att checka in och säkerhetskontroller och ta emot
      även med tanke på att det kunde hända saker på vägen till flygplatsen. Många flygbolag erbjuder incheckning på sin hemsida cirka 22 timmar före avgång.
      För frågor om check online hänvisar vi till flygbolaget du reser med.
    </p>
    <div>
      <b>Bagage.</b>
    </div>
    <p>
      Resenären ansvarar för att kontrollera vilka bagageregler som gäller. Information finns på biljetten eller respektive flygbolags hemsida. Itours tar inget
      ansvar för förlorat eller skadat bagage. Eventuella fel ska åtalas omedelbart på flygplatsen, flygbolagets representant.
    </p>
    <p>
      Observera att vissa flygbolag tar ut en bagageavgift. Detta betalas med check direkt till flygbolaget. Det gäller till exempel. Air Baltic, amerikanska
      flygbolag (inrikes USA). Genom att resa med mer än ett flygbolag kan det finnas olika regler för de olika företagen.1 PC = 1 st (max 1 påse á 23 kg)
      Norwegian 1 st = 1 st (max 1 påse x 20 kg)
    </p>
    <div>
      <b>Extra bagage.</b>
    </div>
    <p>
      Tyvärr har vi inte möjlighet att lägga till mer lagringsutrymme. Kontakta flygbolaget direkt för mer information om möjliga möjligheter till extra bagage
      samt kostnad. Information om bagage brukar finnas på flygbolagets hemsida.
    </p>
    <div>
      <b>Specialbagage.</b>
    </div>
    <p>
      De flesta flygbolag vill att du meddelar i förväg om du ska ta med specialbagage på resan. Vi behöver veta vikten och måtten (längd x höjd x bredd) i
      specialpåsar. Du får oftast svar från flygbolaget inom några timmar. Avgiften för detta betalas alltid direkt till flygbolaget.
    </p>
    <p>
      För specialbagage som övervägs: skidåkning, golf, vapen, musikinstrument, etc. Observera att vissa mindre flygplanstyper inte kan ta emot större special
      bagage. Itours kan bara skicka en förfrågan till flygbolaget när det gäller specialbagage, vi kan aldrig garantera att förfrågan kommer att godkännas
      förrän vi har fått bekräftelse från flygbolaget.
    </p>
    <div>
      <b>Innehåll i handbagage.</b>
    </div>
    <p>
      För information om vätskor, medicin, barnmat och specialkost i handbagaget hänvisar vi till Airlines webbsidor. Incheckat bagage påverkas inte av
      restriktioner.
    </p>
    <div>
      <b>Nekad ombordstigning, avbokningar och förseningar.</b>
    </div>
    <p>
    I enlighet med europeisk lag under vissa omständigheter rätt till återbetalning och/eller ersättning från flygbolaget i samband med nekad
       ombordstigning, avbokningar och förseningar. Mer information om dessa rättigheter presenteras på europeiska flygplatser, och de är också tillgängliga från flygbolagen.
       Ersättning i sådana fall är dock flygbolagets ansvar.
    </p>

    <h3 className="large-title">INTEGRITET. DIN INFORMATION. DIN SÄKERHET.</h3>
    <div>
      <b>Våra åtaganden gentemot dig</b>
    </div>
    <p>
    Du är i centrum för allt vi gör. Vårt mål är att alltid känna sig trygga och lita på oss genom att hantera dina personuppgifter med respekt och tillåtelse
       du kontrollerar dem. Det är viktigt att du vet vilken personlig information Itours AS (här kallat "vi" eller "Itours AS") samlar in om dig
       och hur vi använder dem.
    </p>
    <p>
    Vi har gjort vårt bästa för att förklara detta på ett kortfattat och enkelt sätt. Om du vill ha mer information eller om du har frågor, vänligen kontakta vår integritet
       representant genom att skicka ett mail till online@itours.no.
    </p>
    <p>Om vi skulle göra större ändringar i vår integritetspolicy vill vi meddela dig det. Vid behov kommer vi också att begära ditt godkännande</p>

    <div>
      <b>Integritetspolicy</b>
    </div>
    <p>
    Vi har utformat vår sekretesspolicy med dig i åtanke. Hur uttalandet gäller dig beror på vilken typ av kontakt du har med oss. För
       till exempel om du:
    </p>
    <ul>
      <li>
      När du köper en biljett hos oss använder vi den information du ger oss för att uppfylla både våra och Partners skyldigheter att tillhandahålla tjänsten till dig och, om
         tillåtet kommer vi att hålla dig informerad om andra arrangemang som kan vara av intresse för dig; och
      </li>
      <li>
      När vi surfar på våra webbplatser använder vi cookies för att ge dig en personlig och förhoppningsvis sömlös upplevelse. De val och rättigheter du har i
         olika fall förklaras mer i detalj nedan.
      </li>
    </ul>
    <div>
      <b>Vilken information vi har och varifrån vi får den</b>
    </div>
    <ul>
      <li>
      När du skapar ett konto, köper en biljett eller en vän överför en biljett till dig samlar vi in information om dig som, beroende på vilken tjänst vi
         tillhandahåller, kan inkludera din kontakt- och faktureringsinformation.
      </li>
      <li>
      När du använder våra webbplatser eller applikationer samlar vi in information, såsom vilken webbläsare och enhet du använder, din IP-adress, var du befinner dig, vilken webbplats du använder.
         kom ifrån, vad du använde och inte använde vår sida/applikation eller vilken webbplats du gick till när du lämnade vår sida.
      </li>
      <li>
      När du använder en funktion för sociala medier på vår webbplats eller i våra applikationer och du lägger upp något på sociala medieplattformar, kommer de sociala medierna att tillhandahålla
         oss med lite information om dig.
      </li>
      <li>
      I de få fall vi samlar in personuppgifter från barn ber vi alltid om vårdnadshavarens samtycke och kommer alltid att inhämta sådan information för
         de syften som anges när vi hämtar dem.
      </li>
      <li>
      Vi använder leverantörer som slår samman geodemografisk data, vilket hjälper oss att bättre anpassa våra tjänster efter dina behov. Om du föredrar att inte göra detta, se avsnittet
         "Dina val och rättigheter" nedan.
      </li>
    </ul>
    <div>
      <b>Hur din information används och varför</b>
    </div>
    <p className="semi-bold">1. För att uppfylla vårt kontrakt med dig</p>
    <p>Vi använder din information när du ingår ett avtal med oss (t.ex. för att köpa varor eller en biljett) för att:</p>
    <ul>
      <li>behandla din beställning</li>
      <li>ladda, och</li>
      <li>ge dig stöd.</li>
    </ul>
    <p className="semi-bold">2. För våra legitima affärsintressen</p>
    <ul>
      <li>För att skicka dig e-post relaterade till kundtjänst, inklusive orderbekräftelse och händelsepåminnelse.</li>
      <li>
      För att förhindra eller upptäcka olagligt beteende, för att skydda eller upprätthålla våra lagliga rättigheter eller lagen på annat sätt tillåter. T.ex. för att se till att biljetterna kommer
         i händerna på sanna fans. För att uppnå detta kan vi använda din information för att förhindra björnbärsförsäljning av biljetter, missbruk av vår intellektuella
         äganderätt (som våra eller våra dotterbolags varumärken), bedrägeri eller andra brott.
      </li>
      <li>För att säkerställa att vår och våra partners aktiviteter utförs säkert och säkert.</li>
    </ul>
    <p className="semi-bold">3. Where you have given your consent</p>
    <ul>
      <li>
      För att kontakta dig med information eller erbjudanden om kommande evenemang, produkter eller tjänster vi kan erbjuda - E-post, Push och webbaserade varningar, SMS eller
         Sociala medieplattformar.
      </li>
      <li>
      Att behandla din hälsorelaterade information för att uppfylla dina krav på tillgänglighet, där det uttryckligen har begärts och samtycke
         har beviljats.
      </li>
    </ul>
    <div>
      <b>Dina val och rättigheter Alternativ.</b>
    </div>
    <p>Om du har gett oss ditt samtycke kan du återkalla det genom att göra följande:</p>
    <ul>
      <li>
      För att inte längre få marknadsföringsmaterial från oss kan du ändra inställningarna för dina kontoinställningar, följ instruktionerna för att
         avsluta prenumerationen, tillhandahålls alltid i e-postmeddelanden vi skickar till dig, eller kontakta oss och be oss göra det åt dig. Dina rättigheter Du har också rättigheter angående hur din
         personuppgifter används, inklusive:
      </li>
      <li>Rätten att motsätta sig att vi behandlar dina uppgifter.</li>
      <li>Rätten att begära att din information raderas eller begränsas ytterligare.</li>
      <li>Rätten att begära en kopia av den information vi har om dig.</li>
      <li>
      Rätten att rätta, ändra eller uppdatera den information du har gett oss (om du har ett konto hos oss kan du även göra detta genom att logga in och
         uppdatera din information).
      </li>
      <li>
      Rätten att bestrida alla typer av automatiserade beslut vi tar om dig. Ett automatiserat beslut är ett beslut som tas utan mänsklig inblandning och som
         har juridiska konsekvenser (t.ex. kreditupplysning). Vi använder vanligtvis inte automatiserat beslutsfattande, men om vi gör det kommer vi att informera dig om sådana beslut.
      </li>
    </ul>
    <p>
    För att utöva ovanstående rättigheter, skicka oss ett e-postmeddelande: online@itours.no. Var medveten om att även om vi överväger alla förfrågningar vi tar emot noggrant;
       dina rättigheter kan variera beroende på var du bor och vi behöver inte alltid följa din begäran. Om så var fallet kommer vi att förklara varför.
    </p>
    <div>
      <b>Hur vi tar hand om din information.</b>
    </div>
    <p>Vi har vidtagit säkerhetsåtgärder för att skydda din information. De säkerhetsåtgärder vi använder beror på vilken typ av information som samlas in.</p>
    <p>
    Vi kommer endast att lagra din information så länge det är nödvändigt för att tillhandahålla de tjänster du begär för de syften som beskrivs i detta uttalande och för
       något juridiskt syfte där vi är lagligt skyldiga att behålla informationen. Vi kommer att radera din information på ett säkert sätt när det inte längre behövs
       dessa ändamål, i enlighet med Bolagets riktlinjer.
    </p>
    <p>
    Det finns strikta regler för överföring av information på detta sätt för att säkerställa att dina uppgifter fortfarande är skyddade enl. hög standard. Var
       vi gör detta kommer vi att se till att nödvändiga garantier finns på plats. När din information överförs till en plats utanför den europeiska ekonomin
       I området kommer vi att använda en av procedurerna nedan.
    </p>
    <ul>
      <li>Standardavtal godkända av Europeiska kommissionen</li>
      <li>Privacy Shield Framework mellan EU och USA</li>
      <li>Bindande gruppregler för överföring</li>
      <li>Bindande gruppregler för överföring för databehandlare</li>
    </ul>
    <p>Kontakta oss för mer information eller för att få en kopia av den aktuella dokumentationen.</p>
    <div>
      <b>Kontakta oss.</b>
    </div>
    <p>
    If you have questions about the above information or about how we handle privacy, we have your own Personal Representative and your own Privacy
      Representative who can help you: online@itours.no
    </p>
    <p>Du kan också kontakta Datainspektionen, men vi uppmanar dig att först försöka hjälpa till.</p>
  </div>
);

export default TermsSe;
