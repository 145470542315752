import React, { useEffect, useState , useRef} from "react";
import { connect } from "react-redux";
import { Link, useLocation, useHistory } from "react-router-dom";

import { toggleAccountWindow } from "../../Actions/VisualDataAction";
import { SwitchAccountWindow } from "../../Actions/VisualDataAction";
import { GetReserveById } from "../../Actions/PaymentAction";
import { setCurrency } from "../../Actions/UserAction";

import ProfileBallon from "../Admin/ProfileBallon";
import IrLogin from "../Admin/IrLogin/IrLogin";
import LayoutConfig from "../../Config/layoutConfig";


const Header = props => {

  const history = useHistory();
  
  const [trackEmail,setTrackEmail] = useState(null);
  const [trackReserveID,setTrackReserveID] = useState(null);

  const [menuOpen,setMenuOpen] = useState(false);

  const [disableLanguage,setDisableLanguage] = useState(false);

  const [showTrack,setShowTrack] = useState(false);

  const TrackingOrderWrapper = useRef();

  const handleClickOutsideTrackingOrder = (e) => {
    if (TrackingOrderWrapper.current && !TrackingOrderWrapper.current.contains(e.target)) {
      setShowTrack(false);
      setTrackEmail(null);
      setTrackReserveID(null);
    }
  };

  useEffect(() => {
    
    if (props.LAN === "IR"){
      props.setCurrency("IRR");
    }

    document.addEventListener('mousedown', handleClickOutsideTrackingOrder);

    return () => {
        document.removeEventListener('mousedown', handleClickOutsideTrackingOrder);
    };
}, []);

  const location  = useLocation();
  const routerInfo = location.pathname.split("/")[1];

  useEffect(()=>{

    setDisableLanguage(routerInfo === "payment");

  },[routerInfo]);

  const toggleMenuOpen = () => {
    setMenuOpen(prevState => !prevState);
  }
  const closeMenu = () => {
    setMenuOpen(false);
  }

  const trackOrderHandler = () => {
    
    setShowTrack(false);
    setTrackEmail(null);
    setTrackReserveID(null);

     let queryParams = location.search?.split("#")[0];
     const pathReserveId = queryParams.split("reserveId=")[1]?.split("&")[0];
     const pathUserName = queryParams.split("userName=")[1]?.split("&")[0]; 
     const enterdReserveId = trackReserveID;
     const enterdUserName = trackEmail;
     if (pathReserveId && pathUserName && pathReserveId === enterdReserveId && pathUserName === enterdUserName){
      let params = new URLSearchParams();
      params.append("reserveId", enterdReserveId);
      params.append("userName", enterdUserName);
      props.GetReserveById(params);
     }else{
       history.push(
         `/profile/details?userName=${trackEmail}&reserveId=${trackReserveID}`
       );
     }
  }

  const {
    Dictionary,
    LAN,
    SwitchAccountWindow,
    Verification,
    toggleAccountWindow,
    userData,
    smPixel,
    smHotelPixel
  } = props;

    const currencies = ["DKK","SEK","NOK","EUR","AED","USD","TRY"];
    if (LAN === "IR"){
      currencies.push("IRR");
    }
    currencies.sort();

    const logo = <div className="header-logo-holder">
      <Link className="logo" to={"/"}>
        <img src={LayoutConfig.logo} alt={LayoutConfig.companyName} />
      </Link>
    </div> ;

    const account =
      LayoutConfig.themeClassName === 'padide-com-theme' ? null : (        
          Verification ? (
            <ProfileBallon  />
          ) : process.env.REACT_APP_THEME === 'ITOURS_IR' ? (
            <IrLogin />
          ) : (
            <div className="account">
              <div
                className="link inline-block"
                onClick={() => {
                  SwitchAccountWindow(false);
                  toggleAccountWindow();
                }}>
                {LayoutConfig.themeClassName === 'style-2' ? `${Dictionary.login} / ${Dictionary.register}` : Dictionary.login}
              </div>
            </div>
          )
      );

      let buttonClassNames = "button color-1-btn track-order-btn small-btn";

      if (LayoutConfig.themeClassName==="style-3"){
        buttonClassNames = "track-order-btn";
      }else if (LayoutConfig.themeClassName ==="style-2"){
        buttonClassNames = "button color-1-btn track-order-btn";
      }
      
    const trackingOrder = (<div className={`${LayoutConfig.themeClassName==="style-2"?"style-2-trackingOrder":"relative"}`} ref={TrackingOrderWrapper} >
      <button
        type="button"
        className={buttonClassNames}
        onClick={() => {
          setShowTrack(prevState => !prevState);
          setTrackEmail(null);
          setTrackReserveID(null);
        }}
      >
        {Dictionary.trackOrder}
      </button>
      {showTrack && (
        <div className={"tracking"}>
          <div className={"trackingContainer"}>
            <div className={"username form-group"}>
              <label htmlFor="trackingUsername">
                *{Dictionary.email}
              </label>
              <input
                className="form-input placeholder-right"
                id={"trackingUsername"}
                value={trackEmail}
                onChange={(e) => {setTrackEmail( e.target.value )}}
                type={"text"}
                placeholder={Dictionary.enterYourEmail}
              />
            </div>
            <div className={"reserveNumber form-group"}>
              <label htmlFor="trackingUsername">
                *{Dictionary.reservationCode}{" "}
              </label>
              <input
                className="form-input placeholder-right"
                id={"trackingUsername"}
                value={trackReserveID}
                onChange={(e) => {setTrackReserveID(e.target.value)}}
                type="text"
                placeholder={
                  Dictionary.enterTheReservationCodeYouReceived
                }
              />
            </div>
            <div className="clearfix">
              <button
                type="button"
                className="button color-1-btn"
                disabled={!(trackEmail && trackReserveID)}
                onClick={trackOrderHandler}
              >
                {Dictionary.send}
              </button>
              <button
                type="button"
                className="button red-btn pull-end"
                onClick={() => {
                  setShowTrack(false);
                  setTrackEmail(null);
                  setTrackReserveID(null);
                }}
              >
                {Dictionary.close}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>);

    const mainMenu =(<div className="main-menu-wrapper">
      <button
        type="button"
        className="menu-toggle-btn"
        onClick={toggleMenuOpen}
      >
        <span className="menu-btn-bar icon-top-bar"></span>
        <span className="menu-btn-bar icon-middle-bar"></span>
        <span className="menu-btn-bar icon-bottom-bar"></span>
      </button>
      <div>
        {!!menuOpen && (
          <span
            className="menu-overlay"
            onClick={closeMenu}
          />
        )}
        
        <div className={`main-menu-holder ${menuOpen?"expanded":""}`}>
          
          <button
            type="button"
            className="opened menu-toggle-btn red-arrow-right itours-icon mirror-ltr"
            onClick={closeMenu}
          />

          <ul className="main-menu">

            {LayoutConfig.hasDomesticFlight ?
              <li className={`menu-item ${routerInfo === "domesticflight" ||  (routerInfo === "" && (LayoutConfig.hasDomesticFlight)) ? "active": ""}`} >
                <Link
                  to={"/"}
                  className="menu-link"
                  onClick={closeMenu}
                >
                  <span className="itours-icon top-plane-gray hidden-medium hidden-large margin-end-light" />
                  {Dictionary.domesticFlight}
                </Link>
              </li>
            :null}                      
            
            <li
              className={`menu-item ${(routerInfo === "" && (!LayoutConfig.hasDomesticFlight)) || routerInfo === "flight" || routerInfo === "foreignflight" ? "active": ""}`}
            >
              <Link
                to={LayoutConfig.hasDomesticFlight?"/foreignflight":"/"}
                className="menu-link"
                onClick={closeMenu}
              >
                <span className="itours-icon top-plane-gray hidden-medium hidden-large margin-end-light" />
                {Dictionary.foreignFlight}
              </Link>
            </li>
            
            {!!LayoutConfig.hasDomesticHotel && <li className={`menu-item ${routerInfo === "domesticHotel"? "active": ""}`} >
              <Link to="/domesticHotel" className="menu-link" onClick={closeMenu} >
                <span className="itours-icon hotel-gray hidden-medium hidden-large margin-end-light" />
                {Dictionary.domesticHotel}
              </Link>
            </li>}

            <li className={`menu-item ${routerInfo === "hotel"? "active": ""}`} >
              <Link
                to={"/hotel"}
                className="menu-link"
                onClick={closeMenu}
              >
                <span className="itours-icon hotel-gray hidden-medium hidden-large margin-end-light" />
                {Dictionary.foreignHotel}
              </Link>
            </li>

            <li className={`menu-item ${routerInfo === "package"? "active": ""}`} >
              <Link
                to={"/package"}
                className="menu-link"
                onClick={closeMenu}
              >
                <span className="itours-icon package-gray hidden-medium hidden-large margin-end-light" />
                {Dictionary.package}
              </Link>
            </li>                      

            {LayoutConfig.themeClassName==="style-2" || LayoutConfig.themeClassName==="style-3" || <>
              <li className={`menu-item ${routerInfo === "terms"? "active": ""}`} >
                <Link
                  to={"/terms"}
                  className="menu-link"
                  onClick={closeMenu}
                >
                  <span className="itours-icon terms hidden-medium hidden-large margin-end-light" />
                  {Dictionary.termsAndConditions}
                </Link>
              </li>

              {LayoutConfig.themeClassName==="style-3" || <li className={`menu-item ${routerInfo === "about"? "active": ""}`} >
                <Link
                  to={"/about"}
                  className="menu-link"
                  onClick={closeMenu}
                >
                  <span className="itours-icon about-us hidden-medium hidden-large margin-end-light" />
                  {Dictionary.aboutUs}
                </Link>
              </li>}

              {LayoutConfig.themeClassName==="style-3" || <li className={`menu-item ${routerInfo === "contact"? "active": ""}`} >
                <Link
                  to={"/contact"}
                  className="menu-link"
                  onClick={closeMenu}
                >
                  <span className="itours-icon contact-us hidden-medium hidden-large margin-end-light" />
                  {Dictionary.contactUs}
                </Link>
              </li>}
            </>
          }
          </ul>
        </div>
      </div>
    </div>);

    let LanguageElement = <div className="link margin-end-half inline-block">
      <select
        className="itours-select"
        onChange={(e) => {window.location = e.target.value;}}
      >
        {process.env.REACT_APP_SERVER_LOCALSERVER === "itours.ir" && (
          <option value="https://itours.ir" selected >فارسی</option>
        )}

        <option value="https://itours.co" selected={LAN === "US"}>English</option>
        <option value="https://itours.no" selected={LAN === "NO"}>Norsk</option>
        <option value="https://itours.se" selected={LAN === "SE"} >Sverige</option>
        <option value="https://itours.dk" selected={LAN === "DK"}>Dansk</option>
        <option value="https://itours.ae" selected={LAN === "AR"} >العربية</option>
        <option value="https://travelport.as" selected={LAN === "TR"} >Turkish</option>
      </select>
    </div>

    if (
      process.env.REACT_APP_SERVER_LOCALSERVER === "nortravel.se"
      ||
      process.env.REACT_APP_SERVER_LOCALSERVER === "padide81.com"
      ||
      process.env.REACT_APP_SERVER_LOCALSERVER === "sunhotel.ir"
      ||
      process.env.REACT_APP_SERVER_LOCALSERVER === "namintravel.com"
      ||
      process.env.REACT_APP_SERVER_LOCALSERVER === "ayanair.com"
      ||
      process.env.REACT_APP_SERVER_LOCALSERVER === "santana.imt.as"
      ||
      process.env.REACT_APP_SERVER_LOCALSERVER === "itours.ir") {
      LanguageElement = null;
    }



if (LayoutConfig.themeClassName === "style-2"){
  return(
    <header className="style-2-header">
      
    {smPixel && <img src={smPixel} style={{width:"1px",height:"1px",opacity:0}} alt="safarmarketpixel"/>}
    {smHotelPixel && <img src={smHotelPixel} style={{width:"1px",height:"1px",opacity:0}} alt="safarmarketpixel"/>}

    <div className="page-container">
      <div className={`style-2-header-inner relative ${Verification?"is-logged-in":""}`}>
        <div className="style-2-trackOrderAccount">
          {trackingOrder}
          <div className="style-2-account" >
            {account}
          </div>
        </div>
        <div className="style-2-logoMenu">
          {logo}
          {mainMenu}
        </div>
      </div>
    </div>
  
  </header>
  )
}

  if (LayoutConfig.themeClassName === "style-3"){
    const headerContact = (
      <>
        <a href="tel:+982191008091" className="pull-end link-style-btn en-font phone-number hidden-xsmall hidden-small"> 021-91008091 </a>
        <a href="mailto:online@itours.ir" target="_blank" rel="noreferrer" class="pull-end link-style-btn en-font email-address hidden-xsmall hidden-small"> Online@itours.ir </a>
      </>
    );

    return(
      <header className="homeNavbar navbar box-shadow">
        {smPixel && <img src={smPixel} style={{width:"1px",height:"1px",opacity:0}} alt="safarmarketpixel"/>}
        {smHotelPixel && <img src={smHotelPixel} style={{width:"1px",height:"1px",opacity:0}} alt="safarmarketpixel"/>}        

        <div className="style3-top-header">
          <div className="page-container clearfix">
          {headerContact}
          {account}
          </div>
        </div>
        
        <div className="style3-main-header">
          <div className="page-container style3-main-header-inner clearfix">
            {logo}
            {mainMenu}
            {trackingOrder}
          </div>
        </div>

      </header>       
    )
  }

  let currencyElement = <div className="link margin-end-half inline-block">
    <select
      disabled={disableLanguage}
      defaultValue={userData.Currency}
      className="itours-select"
      onChange={(e) => {
        props.setCurrency(e.target.value);
        setTimeout(()=>{
          window.location.reload()
        },[200])
      }}
    >
      {currencies.sort().map(currency => (
        <option value={currency} selected={userData?.Currency === currency}> {currency} </option>
      ))}
    </select>
  </div>;

    return <header className="homeNavbar navbar box-shadow">
        {smPixel && <img src={smPixel} style={{width:"1px",height:"1px",opacity:0}} alt="safarmarketpixel"/>}
        {smHotelPixel && <img src={smHotelPixel} style={{width:"1px",height:"1px",opacity:0}} alt="safarmarketpixel"/>}        

        <div className="page-container">
          <div className="header-main-section">
            <div className="top-header">
              
              {currencyElement && LanguageElement ?
                <div>
                  {currencyElement}
                  {LanguageElement}
                </div> : <>
                  {currencyElement}
                  {LanguageElement}
                </>}

              {account}
            </div>
            {logo}
          </div>
        </div>
        <div className="header-bottom-section">
          <div className="page-container">
            <div className="clearfix">
              <div className="pull-end tracking-order-holder">
                {trackingOrder}
              </div>
              <div className="pull-start">
                {mainMenu}
              </div>
            </div>
          </div>
        </div>
      </header>  
}

const MapStateToProps = (state) => ({
  Verification: state.VisualData.Verification,
  userData: state.UserData,
  Dictionary: state.VisualData.Dictionary,
  LAN: state.VisualData.LAN,
  smPixel:state.FlightSearch.safarmarketPixel,
  smHotelPixel:state.HotelSearch.safarmarketPixel
});

const MapAction = (dispatch) => ({
  toggleAccountWindow: () => dispatch(toggleAccountWindow()),
  SwitchAccountWindow: (mode) => {dispatch(SwitchAccountWindow(mode));},
  setCurrency: (value) => {dispatch(setCurrency(value));},
  GetReserveById: (param) => {dispatch(GetReserveById(param));},
});

export default connect(MapStateToProps, MapAction)(Header);
