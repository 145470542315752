import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Price from "../Common/Price";
import axios from "axios";
import { withRouter } from "react-router-dom";
import { Flight as FlightURL, Header, ServerAddress } from "../../Enum/Urls";
import { showError,toggleAccountWindow } from "../../Actions/VisualDataAction";
import {setNaminTravelSelectedFlight} from "../../Actions/FlightAction";
import FlightItinerary from "./FlightItinery";
import RouteAllSegments from "./RouteAllSegments";
import { Collapse ,Tag} from "antd";
import BaggageInfoItem from "./BaggageInfoItem";
import AlternativePrices from "./AlternativePrices";
import LayoutConfig from "../../Config/layoutConfig";
import FlightListItem from "./FlightListItem";

class FlightItem extends React.Component {
  static propTypes = {
    model: PropTypes.any.isRequired,
  };
  state = {
    showDetails: false,
    domesticRefundRules:null,
    rulesContent: null,
    activeTab: "flightItinery",
    validateResponse:null,
    alternativePrices : undefined,
    alternativePricesModalVisibility : false
    //,requiredLoginModalVisibility:false
  };
  getRules = (key) => {
    axios({
      method: "get",
      url: `${ServerAddress.Type}${ServerAddress.Flight}${FlightURL.GetRules}`,
      params: { key },
      headers: { ...Header },
    })
      .then((response) => {
        this.setState({
          rulesContent: response.data.result,
        });
      })
      .catch((error) => {
        try {
          this.props.showError({
            visibility: true,
            content: error.response.data.error.message,
          });
        } catch (e) {
          this.props.showError({
            visibility: true,
            content: "Internal Error,Please refresh the page",
          });
        }
      });
  };
  getDomesticRefundRules = (key) => {
    axios({
      method: "get",
      url: `${ServerAddress.Type}${ServerAddress.Flight}${FlightURL.GetDomesticRefundRules}`,
      params: { key },
      headers: { ...Header },
    })
      .then((response) => {
        this.setState({
          domesticRefundRules: response.data.result,
        });
      })
      .catch((error) => {
        try {
          this.props.showError({
            visibility: true,
            content: error.response.data.error.message,
          });
        } catch (e) {
          this.props.showError({
            visibility: true,
            content: "Internal Error,Please refresh the page",
          });
        }
      });
  };

  validate = (key) => {
    axios({
      method: "post",
      url:`${ServerAddress.Type}${ServerAddress.Flight}${FlightURL.ValidateFlight}?key=${key}`,
      headers: { ...Header },
    })
      .then((response) => {
        this.setState({
          validateResponse: response.data.result,
        });
      })
      .catch((error) => {
        try {
          this.props.showError({
            visibility: true,
            content: error.response.data.error.message,
          });
        } catch (e) {
          this.props.showError({
            visibility: true,
            content: "Internal Error,Please refresh the page",
          });
        }
      });
  };
  
  toggleDetails = (value) => {
    if (value){
      this.setState({showDetails: value});
    }else{
      this.setState((prevstate) => {
        return {
          showDetails: !prevstate.showDetails,
        };
      });
    }
  };

  tabClick = (item) => {
    this.setState({
      activeTab: item,
    });
    if (item === "flightRules") {
      if (!this.state.rulesContent){
        this.getRules(this.props.model.key);
      }
    }
    if (item === "domesticRefundRules") {
      if (!this.state.domesticRefundRules){
        this.getDomesticRefundRules(this.props.model.key);
      }
    }
    if (item === "baggageInfo"){

        //%%%%
        let validateForBaggageInfo = false;
        //validateForBaggageInfo= true;

      if(!this.state.validateResponse && validateForBaggageInfo){
        this.validate(this.props.model.key)
      }
    }
  };
  bookFlight = (key)=>{
    // if (process.env.REACT_APP_THEME !=='NAMINTRAVELCOM%%%%%AYANAIRCOM' || this.props.isEmailConfirmed){
      let travelType = 'flight';
      if(this.props.domestic){
        travelType = 'domesticflight';
      }
      this.props.history.push(
        `/${travelType}/getpassenger?key=${key}`
      );
    // }else{
    //   this.setState({...this.state,requiredLoginModalVisibility:true});
    //   this.props.setNaminTravelSelectedFlight({key:key,extraBaggage:false});
    // }
  }
  bookFlightWithExtraBaggage = (key)=>{
    // if (process.env.REACT_APP_THEME !=='NAMINTRAVELCOM%%%%%AYANAIRCOM' || this.props.isEmailConfirmed){
      let travelType = 'flight';
      if(this.props.domestic){
        travelType = 'domesticflight';
      }
      this.props.history.push(
        `/${travelType}/getpassenger?key=${key}&extraBaggage=true`
      );
    // }else{
    //   this.setState({...this.state,requiredLoginModalVisibility:true});
    //   this.props.setNaminTravelSelectedFlight({key:key,extraBaggage:true});
    // }
  }
  openDetailsAndShowRoules = ()=>{
    this.setState({...this.state,showDetails:true});
    this.tabClick("flightRules");
  }


  getAlternativePrices = () => {
    this.setState({
      alternativePrices: "loading",
    });
    axios({
      method: "get",
      url:`${ServerAddress.Type}${ServerAddress.Flight}${FlightURL.GetAlternatives}?key=${this.props.model.key}`,
      headers: { ...Header },
    })
      .then((response) => {
        this.setState({
          alternativePrices: response.data.result,
        });
      })
      .catch((error) => {
        this.setState({
          alternativePrices: undefined,
          alternativePricesModalVisibility : false
        });
        try {
          this.props.showError({
            visibility: true,
            content: error.response.data.error.message,
          });
        } catch (e) {
          this.props.showError({
            visibility: true,
            content: "Internal Error,Please refresh the page",
          });
        }
      });
  };

  showAlternativePrices = () => {
    if (!this.state.alternativePrices){
      this.getAlternativePrices();
    }
    this.setState({
      alternativePricesModalVisibility:true
    })
  }

  closeAlternativesModal = () =>{
    this.setState({
      alternativePricesModalVisibility:false
    })
  }

  render() {
    const { Panel } = Collapse;
    const { Dictionary,model } = this.props;
    
    const priceInfo = model.airItineraryPricingInfo.itinTotalFare;
    const totalPrice = priceInfo.totalFare;

    let baggageAvailable = true;
    let airportChange = false;

    const flightType = model.flightType && <Tag 
      className="margin-end-light"
      color={model.flightType==="Systemic"?"purple":"orange"}
    >
      {model.flightType==="Systemic"?
        Dictionary.Systemic
      :model.flightType==="Charter"?
        Dictionary.Charter
      : model.flightType}
    </Tag>

    const availableSeats = model.availableSeats && (<span className="available-seats purple margin-end-light font-14 xs-font-12">
      {Dictionary.availableSeats}: {model.availableSeats}  
    </span>);
    
    model.airItinerary.originDestinationOptions.forEach((item) => {
      item.flightSegments.forEach((segmentItem, index) => {
        segmentItem.baggageInformation.filter(x=>x.passengerType !== "INF").forEach((baggageItem) => {
          if (baggageItem.baggageAllowance < 1) {
            baggageAvailable = false;
          }
        });
        if (index < item.flightSegments.length - 1) {
          if (
            !(
              segmentItem.arrivalAirport.locationCode ===
              item.flightSegments[index + 1].departureAirport.locationCode
            )
          ) {
            airportChange = true;
          }
        }
      });
    });
    // let extraBaggageBadge = null;
    // if (process.env.REACT_APP_THEME ==='NAMINTRAVELCOM AYANAIRCOM' && model.isEthnic){
    //   let extraBaggagePrase = Dictionary.withExtraBaggage;
    //   if (model.airItinerary.originDestinationOptions[0].flightSegments[0].marketingAirline.code === "TK"){
    //     extraBaggagePrase = `${Dictionary.with15KExtraBaggage} (${Dictionary.onlyAdultsAndChildren})`;
    //   }
    //   extraBaggageBadge = <span className="extraBaggageBadge margin-end-light">{extraBaggagePrase}</span> ;
    // }

    //%%%%%
    let validateForBaggageInfo = false;
      //validateForBaggageInfo= true;



      let bookBtnClassName = "button red-btn select-flight-btn has-arrow";
      let bookWithExtraBaggageBtnClassName = "button green-btn select-flight-btn margin-start-half has-arrow";
      if (LayoutConfig.themeClassName === 'style-3'){
        bookBtnClassName = "button color-1-btn select-flight-btn has-arrow";
        bookWithExtraBaggageBtnClassName = "button color-1-btn select-flight-btn margin-start-half has-arrow";
      }
    return (
      <>
        {!!this.state.alternativePricesModalVisibility && (
          <div className="itours-modal visible flight-alternative-prices-modal">
            <div className="modal-overlay" onClick={() => { this.setState({ ...this.state, alternativePricesModalVisibility: false }) }} />
            <div className="modal-inner medium-width">
              <AlternativePrices 
                alternativePrices={this.state.alternativePrices} 
                flightKey={model.key} 
                currency = {this.props.currency} 
                bookFlight={this.bookFlight}
                closeAlternativesModal ={this.closeAlternativesModal}
              />
            </div>
          </div>
        )}

        {/* {this.state.requiredLoginModalVisibility && <div className="itours-modal login-to-book-modal visible">
            <div className="modal-overlay" onClick={()=>{this.setState({...this.state,requiredLoginModalVisibility:false})}} />
            <div className="modal-inner small-width">
              <div className="modal-main">
                <span className="itours-icon user-login icon-xxlarge" />
                <h3 className="normal-title">{this.props.userName ? "please Confirm Your Email To Continue" :Dictionary.pleaseLoginToContinue} ...</h3>
                <p className="text-center gray semi-bold margin-bottom-large">
                  <span className="itours-icon info-icon icon-small margin-end-light" />
                  {this.props.userName ? Dictionary.pleaseConfirmYourEmailThenReloadThisPageToContinueTheBooking :Dictionary.toProceedWithBookingAtSpecialPriceYouMustLogIn}
                </p>
                <div className="text-center margin-top-large margin-bottom-large">
                  {this.props.userName ?
                  <button type="button" className="button blue-btn large-btn" onClick={()=>{window.location.reload();}} >
                    {Dictionary.Reload}
                  </button>
                  :<button
                    type="button"
                    className="button red-btn large-btn"
                    onClick={()=>{
                      this.setState({...this.state,requiredLoginModalVisibility:false});
                      this.props.toggleAccountWindow();
                    }}
                  >
                    {Dictionary.login}
                  </button>}               
                </div>
              </div>
            </div>
          </div>
        } */}

        {this.state.showDetails && <div className="overlay" onClick={()=>{this.setState({showDetails:false})}} />}
        <div className="flight-result-item" >
          <div className="item-main">
            <div className="item-main-inner">
              <div className="content">
                {baggageAvailable && (
                  <div className="included-baggage-mark">
                    {process.env.REACT_APP_THEME ==='AYANAIRCOM' ? (
                      <svg viewBox="0 -960 960 960" className="svg-checkmark">
                        <path d="M382-200 113-469l97-97 172 173 369-369 97 96-466 466Z"/>
                      </svg>
                    ):(
                      <span className={`itours-icon ${process.env.REACT_APP_THEME ==='NAMINTRAVELCOM'?"check-yellow":"check-green"} icon-xsmall margin-end-light`} />
                    )}
                    <span className="text"> {Dictionary.BaggageIncluded} </span>
                  </div>
                )}

                {process.env.REACT_APP_THEME === 'ITOURS_IR' || process.env.REACT_APP_THEME ==='AYANAIRCOM' ? (
                  <FlightListItem
                    model={model}
                    onFetchBaggageInfo ={validateForBaggageInfo? () => {this.tabClick("baggageInfo");this.toggleDetails(true)}:undefined}
                  />
                ):(
                <>
                  {model.airItinerary.originDestinationOptions.map(item => (
                    <RouteAllSegments
                      key={item.flightSegments[0].flightNumber}
                      model={item}
                      onFetchBaggageInfo ={validateForBaggageInfo? () => {this.tabClick("baggageInfo");this.toggleDetails(true)}:undefined}
                    />
                    // <Route key={index} model={item} />
                  ))}
                  {airportChange && (
                    <div className="has-airport-change">
                      <span className="itours-icon error2-icon icon-small inline-block-middle margin-end-light" />
                      <span className="purple inline-block-middle font-12 semi-bold">
                        {Dictionary.StopForAirportChange}
                      </span>
                    </div>
                  )}
                </>
                )}


              </div>
            </div>
          </div>
          {this.state.showDetails && (
            <div className="flightDetails margin-bottom">
              <div className="flight-detail-main">            
                <div className="itours-tab-holder">
                  <div className="itours-tab-list">
                    <button
                      type="button"
                      className={`itours-tab-btn ${this.state.activeTab === "flightItinery"? "active": ""}`}
                      onClick={() => this.tabClick("flightItinery")}
                    >
                      {Dictionary.flightItinery}
                    </button>
                    <button
                      type="button"
                      className={`itours-tab-btn ${this.state.activeTab === "baggageInfo"? "active": ""}`}
                      onClick={() => this.tabClick("baggageInfo")}
                    >
                      {Dictionary.baggageInfo}
                    </button>
                    <button
                      className={`itours-tab-btn ${this.state.activeTab === "flightFare"? "active": ""}`}
                      onClick={() => this.tabClick("flightFare")}
                    >
                      {Dictionary.flightFare}
                    </button>
                    {this.props.domestic && (process.env.REACT_APP_SERVER_LOCALSERVER === "santana.imt.as" || process.env.REACT_APP_SERVER_LOCALSERVER === "itours.ir") ?<button 
                      type="button" className={`itours-tab-btn ${this.state.activeTab === "domesticRefundRules"?"active":""}`}
                      onClick={() => this.tabClick("domesticRefundRules")}
                    >
                      قوانین استرداد
                    </button>
                    :
                    <button 
                      type="button"
                      className={`itours-tab-btn ${this.state.activeTab === "flightRules"?"active":""}`}
                      onClick={() => this.tabClick("flightRules")}
                    >
                      {Dictionary.flightRules}
                    </button>
                    }
                  </div>
                  <div className="itours-tab-contents">
                    {this.state.activeTab === "flightItinery" && (
                      <div className="detail-flight">
                        <FlightItinerary model={model.airItinerary.originDestinationOptions} />
                      </div>
                    )}
                    {this.state.activeTab === "baggageInfo" && (
                      <div className="flight-baggage-detail">
                        {validateForBaggageInfo?<>
                          {
                            this.state.validateResponse ?
                            this.state.validateResponse.pricedItinerary?.airItinerary?.originDestinationOptions?.map(item => <BaggageInfoItem model={item} key={item.flightSegments[0].flightNumber} hasExtraBaggage ={this.props.hasExtraBaggage}/>)
                            :
                            <div>
                              {Dictionary.loadingData}
                              <span className="dots-loading">
                                <span className="dot">.</span>
                                <span className="dot">.</span>
                                <span className="dot">.</span>
                              </span>
                            </div>
                          }
                        </>
                        :model.airItinerary.originDestinationOptions.map(item => <BaggageInfoItem model={item} key={item.flightSegments[0].flightNumber} hasExtraBaggage ={this.props.hasExtraBaggage}/>)}
                      </div>
                    )}
                    {this.state.activeTab === "flightFare" && (
                      <div className={"fareContainer"}>
                        <div className="title">{Dictionary.fareTotal}</div>
                        <div className="baseFare">
                          <strong>{Dictionary.baseFare}: </strong>
                          <Price currency={this.props.currency}>
                            {priceInfo.baseFare}
                          </Price>
                        </div>
                        {priceInfo.fess && (priceInfo.fess.length > 0) && (
                          <div className="fees">
                            <strong>{Dictionary.fees}: </strong>
                            <Price currency={this.props.currency}>
                              {priceInfo.fess[0].amount}
                            </Price>
                          </div>
                        )}
                        {priceInfo.taxes && (priceInfo.taxes.length > 0) && (<div className="taxes">
                          <strong>{Dictionary.taxes}: </strong>
                          <Price currency={this.props.currency}>
                            {priceInfo.taxes[0].amount}
                          </Price>
                        </div>)}
                        {this.props.hasExtraBaggage && 
                        <div className="extraBaggage">
                          <strong>{Dictionary.extraBaggage}: </strong>
                          <Price currency={this.props.currency}>
                            {priceInfo.extraBaggage}
                          </Price>
                        </div>                      
                        }
                        <hr />
                        <div className="totalPrice">
                          <strong>{Dictionary.totalPrice}: </strong>
                          <Price currency={this.props.currency}>
                            {priceInfo.totalFare}
                          </Price>
                        </div>

                        {this.props.hasExtraBaggage && <div className="totalPrice">
                          <strong>{Dictionary.totalFareWithExtraBaggage}: </strong>
                          <Price currency={this.props.currency}>
                            {priceInfo.totalFareWithExtraBaggage}
                          </Price>
                        </div>}
                      </div>
                    )}
                    {this.state.activeTab === "flightRules" && (
                      <div className="rules-container">
                        {this.state.rulesContent ? (
                          <>
                            {this.state.rulesContent.flightRules.length === 0 ? (
                              <div>{Dictionary.informationNotFound}</div>
                            ) : (
                              <>
                                {this.state.rulesContent.flightRules.map(
                                  (item, index) => {
                                    return (
                                      <div
                                        className="rules-item en-font"
                                        key={index}
                                      >
                                        <div className="rule-title">
                                          FareBase: {item.fareBase}
                                        </div>
                                        <Collapse>
                                          {item.flightRule.map(
                                            (itemIn, indexIn) => {
                                              return (
                                                <Panel
                                                  header={itemIn.ruleTitle}
                                                  key={indexIn}
                                                >
                                                  <p>{itemIn.ruleDescription}</p>
                                                </Panel>
                                              );
                                            }
                                          )}
                                        </Collapse>
                                      </div>
                                    );
                                  }
                                )}
                              </>
                            )}
                          </>
                        ) : (
                          <div>
                            {Dictionary.loadingData}
                            <span className="dots-loading">
                              <span className="dot">.</span>
                              <span className="dot">.</span>
                              <span className="dot">.</span>
                            </span>
                          </div>
                        )}
                      </div>
                    )}

                    {this.state.activeTab === "domesticRefundRules" && (
                      <div className="rules-container">
                        {this.state.domesticRefundRules ? (
                          <>
                            {this.state.domesticRefundRules.length === 0 ? (
                              <div>{Dictionary.informationNotFound}</div>
                            ) : (
                              <>
                                {this.state.domesticRefundRules[0].type=== 1 && <h5 className="normal-title margin-bottom-large">درصد جریمه استرداد بلیط بر اساس زمان اعلام کنسلی</h5>}
                                {this.state.domesticRefundRules.map(
                                  (item,index) =><div className="margin-bottom" key={index}>
                                    {item.type===1 && <span className="purple font-20 inline-block margin-end" dir="ltr">{item.damage}%</span>}
                                    <span className="inline-block">{item.description}</span>
                                  </div>
                                )}
                              </>
                            )}
                          </>
                        ) : (
                          <div>
                            {Dictionary.loadingData}
                            <span className="dots-loading">
                              <span className="dot">.</span>
                              <span className="dot">.</span>
                              <span className="dot">.</span>
                            </span>
                          </div>
                        )}
                      </div>
                    )}

                  </div>
                  <div className="hidden-medium hidden-large">
                    <div className={`item-footer ${availableSeats ||flightType ? " multiple_rows":""}`}>
                      <div className="flight-footer-top-footer">
                        <button
                          type="button"
                          onClick={() => this.toggleDetails()}
                          className={`button ${LayoutConfig.themeClassName === 'style-3'?"yellow-btn":"blue-btn"} flight-detail-btn`}
                        >
                          {this.state.showDetails ? Dictionary.hideDetails : Dictionary.flightDetails}
                          <span className={this.state.showDetails ? "rot" : null} />
                        </button>
                        <div className="inline-block flight-type-and-seats">
                          {flightType}
                          {availableSeats}
                        </div>
                      </div>
                      <div className="no-wrap text-end price-and-book">
                        <b dir="ltr" className="margin-end-half margin-start-light">
                          <Price currency={this.props.currency}>
                            {totalPrice}
                          </Price>
                        </b>
                        {this.props.disableBook ||
                          <>
                            <button
                              type="button"
                              className={bookBtnClassName}
                              onClick={()=>{this.bookFlight(this.props.model.key)}}
                            >
                              {Dictionary.book}
                            </button>

                            {this.props.hasExtraBaggage && <button
                              type="button"
                              className={bookWithExtraBaggageBtnClassName}
                              onClick={()=>{this.bookFlightWithExtraBaggage(this.props.model.key)}}
                            >
                              {Dictionary.BookWithExtraBaggage} (+ <Price currency={this.props.currency}>{priceInfo.extraBaggage}</Price>)
                            </button>}
                            
                            {this.props.hasExtraBaggage && <div className="text-end font-13">
                              {Dictionary.BaggagePerPersonCanBeSelectedDeselectedInTtheNextStep}
                            </div>}
                          </>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}          
          <div className={`item-footer ${availableSeats ||flightType ? " multiple_rows":""}`}>
            {/* <div>           
              {this.props.model.key.slice(0, 3) === "009" ?
                <>
                <Tag color="red" className="itours-tag">{Dictionary.nonChangeable}</Tag>
                  <Tag color="red" className="itours-tag">{Dictionary.nonRefundable}</Tag>
                </>
                :null
              }
            </div> */}
            <div className="flight-footer-top-footer">
              <button
                type="button"
                onClick={() => this.toggleDetails()}
                className={`button ${LayoutConfig.themeClassName === 'style-3'?"yellow-btn":"blue-btn"} flight-detail-btn margin-end-light margin-start-light margin-bottom-light margin-top-light`}
              >
                { this.state.showDetails ? Dictionary.hideDetails : Dictionary.flightDetails}
                <span className={this.state.showDetails ? "rot" : null} />
              </button>
              <div className="inline-block flight-type-and-seats">
                {flightType}
                {availableSeats}
              </div>
              {process.env.REACT_APP_THEME ==='NAMINTRAVELCOM%%%%AYANAIRCOM' && this.props.model.isEthnic && <button type="button" className="link-style-btn margin-start red" onClick={this.openDetailsAndShowRoules}>{Dictionary.oneFreeChange} <span className="itours-icon help-icon icon-small" /></button>}
              {/* {extraBaggageBadge} */}
            </div>

            <div className="no-wrap text-end price-and-book">
              <b dir="ltr" className="margin-end-half margin-start-light">
                <Price currency={this.props.currency}>
                  {totalPrice}
                </Price>
              </b>
              {this.props.disableBook ||
              <>


                {this.props.model.hasAlternative ? (
                  <button
                    type="button"
                    className={bookBtnClassName}
                    onClick={this.showAlternativePrices}
                  >
                    {Dictionary.seeOtherPrices}
                  </button>
                ) : (
                  <button
                    type="button"
                    className={bookBtnClassName}
                    onClick={() => { this.bookFlight(this.props.model.key) }}
                  >
                    {Dictionary.book}
                  </button>
                )}




              {this.props.hasExtraBaggage && <button
                type="button"
                className={bookWithExtraBaggageBtnClassName}
                onClick={()=>{this.bookFlightWithExtraBaggage(this.props.model.key)}}
              >
                {Dictionary.BookWithExtraBaggage} (+ <Price currency={this.props.currency}>{priceInfo.extraBaggage}</Price>)
              </button>}
              {this.props.hasExtraBaggage && <div className="text-end font-13">
                {Dictionary.BaggagePerPersonCanBeSelectedDeselectedInTtheNextStep}
              </div>}
              </>
              }



            </div>
          </div>
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  Dictionary: state.VisualData.Dictionary,
  LAN: state.VisualData.LAN,
  currency: state.UserData.Currency,
  userName : state.UserData?.userData?.userName,
  isEmailConfirmed : state.UserData?.userData?.isEmailConfirmed
});
const mapDispatchToProps = (dispatch) => ({
  showError: (param) => dispatch(showError(param)),
  // toggleAccountWindow: () => dispatch(toggleAccountWindow()),
  setNaminTravelSelectedFlight : (data) => dispatch (setNaminTravelSelectedFlight(data))
});
const routerBind = withRouter(FlightItem);
export default connect(mapStateToProps, mapDispatchToProps)(routerBind);
