import axios from "axios";
import {
  Header,
  Hotel as HotelURL,
  ServerAddress,
  Reserve,
} from "../Enum/Urls";
import { showError, editMessage } from "./VisualDataAction";
import { push } from "connected-react-router";
import _ from "lodash";
import { getFlightReserveAsync } from "../Actions/FlightAction";

export const setHotelAvailableFilters = (data) => ({
  type: "SET_HOTEL_AVAILABLE_FILTERS",
  data: data,
});
export const setFilter = data => ({type:'SET_FILTER',data:data});

export const resetFilter = () => ({type : 'RESET_FILTER'});

export const selectedHotel = (data) => ({ type: "HotelDetails", result: data });
export const setHotelDetails = hotelData => ({
  type: "HotelDetails",
  result: hotelData,
});
export const setReserveID = (data) => ({
  type: "SetReserveID",
  reserveId: data,
});
export const getHotelReserveAsync = (data) => {
  let voucherList = [];
  if (data) {
    data.forEach((item) => {
      const {
        departureDate,
        currency,
        firstName,
        gender,
        lastName,
        origin,
        salePrice,
        id,
        status,
      } = item;
      let date = new Date(departureDate);
      let itemVoucher = {
        date: date.toLocaleDateString(),
        currency: currency.code,
        firstName,
        gender,
        lastName,
        hotel: origin.name,
        salePrice,
        id,
        status,
      };
      voucherList.push(itemVoucher);
    });
  }
  return { type: "editVoucher", data: voucherList };
};

export const getReserveByIDAsync = (data) => ({
  type: "setVoucherDetails",
  result: { ...data },
});
export const getRoomCancellationAsync = (data) => ({
  type: "getCancellationInfos",
  result: { ...data },
});
export const setCancelationRoom = (data) => ({
  type: "setCancelationRoom",
  result: { ...data },
});

//Sync methods
export const getPreReserveID = (params) => {
  return (dispatch) => {
    axios({
      method: "get",
      url: `${ServerAddress.Type}${ServerAddress.Hotel}${HotelURL.GetPreReserveID}`,
      params: params,
      headers: { ...Header },
    })
      .then((response) => {
        dispatch(setHotelDetails(response.data.result));
        dispatch(editMessage(true));
      })
      .catch((error) => {
        try {
          dispatch(
            showError({
              visibility: true,
              content: error.response.data.error.message,
            })
          );
        } catch (e) {
          dispatch(
            showError({
              visibility: true,
              content: "Internal Error,Please refresh the page",
            })
          );
        }
      });
  };
};
export const getReserveByID = (params) => {
  return (dispatch) => {
    axios({
      method: "get",
      url: `${ServerAddress.Type}${ServerAddress.Hotel}${HotelURL.GetReserveByID}`,
      params: params,
      headers: { ...Header },
    })
      .then((response) => {
        console.log("response reserve ID is");
        console.log("hotel status is:", response.data.result.status);
        dispatch(selectedHotel(response.data.result));
        dispatch(editMessage(true));
      })
      .catch((error) => {
        try {
          dispatch(
            showError({
              visibility: true,
              content: error.response.data.error.message,
            })
          );
        } catch (e) {
          dispatch(
            showError({
              visibility: true,
              content: "Internal Error,Please refresh the page",
            })
          );
        }
      });
  };
};
export const postReserve = (params) => {
  return (dispatch) => {
    //handle header
    let token = localStorage.getItem("userToken");
    let HeaderAthu = null;
    if (token) {
      HeaderAthu = { Authorization: `Bearer ${token}`, ...Header };
    } else {
      HeaderAthu = { ...Header };
    }
    //
    axios({
      method: "post",
      url: `${ServerAddress.Type}${ServerAddress.Hotel}${HotelURL.PostReserver}`,
      data: { ...params },
      headers: HeaderAthu,
    })
      .then((response) => {
        dispatch(setReserveID(response.data.result.reserveId));
        dispatch(editMessage(true));
      })
      .catch((error) => {
        try {
          dispatch(
            showError({
              visibility: true,
              content: error.response.data.error.message,
            })
          );
        } catch (e) {
          dispatch(
            showError({
              visibility: true,
              content: "Internal Error,Please refresh the page",
            })
          );
        }
      });
  };
};
export const getHotelReserve = (type,userEmail) => {
  return (dispatch) => {
    //handle header
    let token = localStorage.getItem("userToken");
    let HeaderAthu = null;
    if (token) {
      HeaderAthu = { Authorization: `Bearer ${token}`, ...Header };
    } else {
      HeaderAthu = { ...Header };
    }
    //
    let requestUrl,params;
    if (type==="NaminTravelFlights"){
      requestUrl =`${ServerAddress.Type}${ServerAddress.Flight}${Reserve.naminTravelFlight}`;
      params = { Email: userEmail };
    }else{
      requestUrl =`${ServerAddress.Type}${ServerAddress.Hotel}${Reserve.hotel}`;
      params = { ReserveTypes: type, PageNumber: 1, PageSize: 100000 };
    }
    axios({
      method: "get",
      url: requestUrl,
      params: params ,
      headers: HeaderAthu,
    })
      .then((response) => {
        if (type === "FlightGlobal" || type === "NaminTravelFlights") {
          dispatch(getFlightReserveAsync(type === "NaminTravelFlights" ? response.data.result.items : response.data.result));
          dispatch(editMessage(true));
        } else {
          dispatch(getHotelReserveAsync(response.data.result));
          dispatch(editMessage(true));
        }
      })
      .catch((error) => {
        try {
          dispatch(
            showError({
              visibility: true,
              content: error.response.data.error.message,
            })
          );
        } catch (e) {
          dispatch(
            showError({
              visibility: true,
              content: "Internal Error,Please refresh the page",
            })
          );
        }
      });
  };
};
export const getHotelVoucherDetails = (params) => {
  return (dispatch) => {
    axios({
      method: "get",
      url: `${ServerAddress.Type}${ServerAddress.Hotel}${HotelURL.GetVoucherDetails}`,
      params,
      headers: { ...Header },
    })
      .then((response) => {
        dispatch(getReserveByIDAsync(response.data.result));
      })
      .catch((error) => {
        try {
          dispatch(
            showError({
              visibility: true,
              content: error.response.data.error.message,
            })
          );
        } catch (e) {
          dispatch(
            showError({
              visibility: true,
              content: "Internal Error,Please refresh the page",
            })
          );
        }
      });
  };
};
export const bookhotel = (params) => {
  return (dispatch) => {
    //handle header
    let token = localStorage.getItem("userToken");
    let HeaderAthu = null;
    if (token) {
      HeaderAthu = { Authorization: `Bearer ${token}`, ...Header };
    } else {
      HeaderAthu = { ...Header };
    }
    //
    axios({
      method: "post",
      url: `${ServerAddress.Type}${ServerAddress.Hotel}${HotelURL.BookHotel}`,
      data: { ...params },
      headers: HeaderAthu,
    })
      .then((response) => {
        const status = response?.data?.result?.status;
        const reserveStatus = response?.data?.result?.reserveStatus;
        debugger;
        if (status === false && reserveStatus === "UnConfirmed"){
          dispatch(setHotelBookingUnconfirm(true));
        }else{
          dispatch(setHotelBookingUnconfirm(false));
          dispatch(
            push(
              `/profile/details?userName=${params.userName}&reserveId=${params.reserveId}`
            )
          );
        }
      })
      .catch((error) => {
        debugger;
        try {
          dispatch(setHotelBookingUnconfirm(true));
          // dispatch(
          //   push(
          //     `/profile/details?userName=${params.userName}&reserveId=${params.reserveId}`
          //   )
          // );
        } catch (e) {
          debugger;
          dispatch(
            showError({
              visibility: true,
              bold:process.env.REACT_APP_SERVER_LOCALSERVER === "itours.ir",
              content: process.env.REACT_APP_SERVER_LOCALSERVER === "itours.ir" ? <div>
                <p>جهت بررسی وضعیت رزرو خود، حتما با پشتیبانی آیتورز تماس بگیرید.</p>
                <span dir="ltr">(+98) 21 91008091 </span>
              </div>
              :"Internal Error,Please refresh the page",
            })
          );
        }
      });
  };
};

export const getCancellationPolicy = async (params) => {
  try {
    const config = {
      method: "get",
      url: `${ServerAddress.Type}${ServerAddress.Hotel}${HotelURL.GetCancellationPolicy}`,
      headers: { ...Header },
      params: params,
    };

    let response = await axios(config);
    return response;
  } catch (error) {
    return error.response.data;
  }
};

export const preReserve = async (param) => {
  let header = { ...Header };
  try {
    let response = await axios.post(`${ServerAddress.Type}${ServerAddress.Hotel}${HotelURL.PreReserve}`,
     param,
     { headers: { ...header } });
    return response.data.result;
  } catch (error) {
    return error.response.data;
  }
};
export const setSafarmarketHotelPixel = (data)=>({type:"safarmarketHotelPixel",data:data});

export const setHotelBookingUnconfirm = (data) => ({
  type: "SET_HOTEL_BOOKING_UNCONFIRM",
  data: data,
});