import moment from "moment-jalaali";
import { Tooltip } from "antd";
import airplane from "../../image/airplane.svg";
import LayoutConfig from "../../Config/layoutConfig";
import { connect } from "react-redux";
import { dateDiplayFormat, displayDuration } from "../../helpers/helpers";

const FlightListOriginDestination = props => {

    const { LAN, Dictionary } = props;

    const logosArray = [...props.originDestination.flightSegments.map(item => ({ src: item.marketingAirline.photoUrl, alt: item.marketingAirline.code, title: item.marketingAirline.name }))];

    logosArray.sort((b, a) => -1);

    const logo = <div className="airlines"> {logosArray.map((item, index) => <img key={index} className={"layer-" + index} src={item.src} alt={item.alt} title={item.title} />)} </div>

    const firstSegment = props.originDestination.flightSegments[0];
    const lastSegment = props.originDestination.flightSegments[props.originDestination.flightSegments.length - 1];
    const hasMultipleAirlines = props.originDestination.flightSegments?.length && props.originDestination.flightSegments.length > 1;

    const airlinesArray = [];

    for (let i = 0; i < props.originDestination.flightSegments.length; i++) {
        const currentSegment = props.originDestination.flightSegments[i];

        airlinesArray.push({
            name: currentSegment.marketingAirline.name,
            type: "marketing"
        });
        if (currentSegment.marketingAirline.code !== currentSegment.operatingAirline.code) {
            airlinesArray.push({
                name: currentSegment.operatingAirline.name,
                type: "operating"
            });
        }
    }

    const airlinesNameAndFlightNumber = (
        <div className="first-segment-details text-start">
            <div className="hidden-xsmall">

            <div className="airline-name" dir="ltr">
                {firstSegment?.marketingAirline?.name}
            </div>

            {firstSegment?.operatingAirline?.name && (firstSegment.marketingAirline.name !==  firstSegment.operatingAirline.name) ? (
                <div className="airline-name operating-airline-name" dir="ltr">
                   <span className="font-12"> Operating by : </span> {firstSegment.operatingAirline.name}
                </div>
            ): null}

            {props.originDestination.flightSegments.length > 1 && (                
                <Tooltip
                    placement="bottom"
                    title={<>
                        {airlinesArray.map((item, index) => (
                            <div key={index} dir="ltr" className={`text-center airline-name ${item.type === "operating" ? "" : "semi-bold font-15"} ${index && item.type === "marketing" ? "border-top" : ""}`}>
                                {item.type === "operating"?  <span className="font-12"> Operating by : </span>  : "" }{item.name}
                            </div>
                        ))}
                    </>}
                >
                    <span className="font-14 link">
                    <svg viewBox="0 -960 960 960" className="svg-icon" width={20} height={20} ><path d="M453-280h60v-240h-60v240Zm26.982-314q14.018 0 23.518-9.2T513-626q0-14.45-9.482-24.225-9.483-9.775-23.5-9.775-14.018 0-23.518 9.775T447-626q0 13.6 9.482 22.8 9.483 9.2 23.5 9.2Zm.284 514q-82.734 0-155.5-31.5t-127.266-86q-54.5-54.5-86-127.341Q80-397.681 80-480.5q0-82.819 31.5-155.659Q143-709 197.5-763t127.341-85.5Q397.681-880 480.5-880q82.819 0 155.659 31.5Q709-817 763-763t85.5 127Q880-563 880-480.266q0 82.734-31.5 155.5T763-197.684q-54 54.316-127 86Q563-80 480.266-80Zm.234-60Q622-140 721-239.5t99-241Q820-622 721.188-721 622.375-820 480-820q-141 0-240.5 98.812Q140-622.375 140-480q0 141 99.5 240.5t241 99.5Zm-.5-340Z"/>
                    </svg> {airlinesArray.length} {Dictionary.airline}
                    </span>
                </Tooltip>
            )}

            </div>

            {!hasMultipleAirlines && <div className="flight-number en-font">{firstSegment.flightNumber}</div>}
        </div>
    );

    const firstSegmentBaggage = firstSegment.baggageInformation.find(baggageItem => baggageItem.passengerType === "ADT");

    const stops = props.originDestination.flightSegments.slice(1).map(item => (item.departureAirport.locationCode));

    return (
        <div className="origin-destination-item">
            <div className="airline-block">
                {logo}
                {airlinesNameAndFlightNumber}
            </div>

            <div className="route-block">

                <div className="location-col text-end">

                    <div className="font-16 margin-bottom-light gray-a">{moment(firstSegment.departureDateTime).format("HH:mm")}</div>

                    <div className="margin-bottom-light font-13">
                        {(LAN === "IR") ? (
                            <Tooltip placement="top" title={dateDiplayFormat({ date: firstSegment.departureDateTime, format: 'YYYY-MM-DD', locale: "en" })} >
                                <b>
                                    {dateDiplayFormat({ date: firstSegment.departureDateTime, format: 'yyyy/mm/dd', locale: LAN === "IR" ? "fa" : "en" })}
                                </b>
                            </Tooltip>
                        ) : (
                            <b>
                                {dateDiplayFormat({ date: firstSegment.departureDateTime, format: 'YYYY-MM-DD', locale: "en" })}
                            </b>
                        )}
                    </div>

                    <div className="">
                        <Tooltip title={<>
                            {firstSegment.departureAirport.cityName}, {firstSegment.departureAirport.countryName} - {firstSegment.departureAirport.locationName}
                        </>}>
                            <div className="font-17 gray-a">
                                {firstSegment.departureAirport.locationCode}
                                {LayoutConfig.themeClassName === 'naminTravel-theme' && <div className='font-12' >
                                    {firstSegment.departureAirport.cityName}, {firstSegment.departureAirport.countryName}
                                </div>}
                            </div>
                        </Tooltip>
                    </div>
                </div>

                <div className="journey-col text-center font-13">
                    <div className="dashed-line-wrapper">
                        <div className="stop-dots-col relative">

                            <div >
                                {stops?.length ? (
                                    <div className="stop-top purple">
                                        {stops.length} {Dictionary.stops}
                                        <div className="stop-dots">
                                            {stops.map((item,index) => <b className="bold text-18 stop-dot-item" key={index}> ({item}) </b>)}
                                        </div>
                                    </div>
                                ) : (
                                    <div className="stop-top green padding-bottom">
                                        {Dictionary.direct}
                                    </div>
                                )}
                            </div>


                            <div className="duration">
                                <span className="margin-end-light">{Dictionary.flightDuration}  </span>
                                <span dir="ltr" className="en-font semi-bold">
                                    {displayDuration(props.originDestination?.journeyDuration)}
                                </span>
                            </div>

                        </div>
                        <img
                            src={airplane}
                            className="mirror-rtl"
                            alt="airplane"
                            width={18}
                            height={18}
                        />
                    </div>
                </div>

                <div className="location-col">
                    <div className="font-16 margin-bottom-light gray-a">
                        {(lastSegment.arrivalDateTime === '0001-01-01T00:00:00') ? " -- : -- " : moment(lastSegment.arrivalDateTime).format("HH:mm")}
                    </div>

                    <div className="margin-bottom-light font-13">
                        {lastSegment.arrivalDateTime === '0001-01-01T00:00:00' ? " ----- "
                            : (LAN === "IR") ? (
                                <Tooltip placement="top" title={dateDiplayFormat({ date: lastSegment.arrivalDateTime, format: 'YYYY-MM-DD', locale: "en" })} >
                                    <b>
                                        {dateDiplayFormat({ date: lastSegment.arrivalDateTime, format: 'yyyy/mm/dd', locale: LAN === "IR" ? "fa" : "en" })}
                                    </b>
                                </Tooltip>
                            ) : (
                                <b>
                                    {dateDiplayFormat({ date: lastSegment.arrivalDateTime, format: 'YYYY-MM-DD', locale: "en" })}
                                </b>
                            )}
                    </div>

                    <div className="">
                        <Tooltip title={<>
                            {lastSegment.arrivalAirport.cityName}, {lastSegment.arrivalAirport.countryName} - {lastSegment.arrivalAirport.locationName}
                        </>}>
                            <div className="font-17 gray-a">
                                {lastSegment.arrivalAirport.locationCode}
                                {LayoutConfig.themeClassName === 'naminTravel-theme' && <div className='font-12'>
                                    {lastSegment.arrivalAirport.cityName}, {lastSegment.arrivalAirport.countryName}
                                </div>}
                            </div>
                        </Tooltip>
                    </div>
                </div>

            </div>
            <div className="baggage-block font-13">
                {props.onFetchBaggageInfo ? <button type='button' className='button link-style-btn' onClick={this.props.onFetchBaggageInfo}>{Dictionary.baggageInfo}</button>
                    : firstSegmentBaggage.baggageAllowance ? (
                        <>
                            <span className='itours-icon icon-small margin-end-light green-baggage' />
                            <span dir="ltr">
                                {firstSegmentBaggage.baggageAllowance} {firstSegmentBaggage.unitType}
                            </span>
                            <span> {Dictionary.for} </span>
                            <span>{Dictionary.adult}</span>
                        </>
                    ) : (
                        <div className='purple'><span className='icon-small itours-icon red-no-baggage margin-end-light' />{Dictionary.noBaggage}</div>
                    )}
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    Dictionary: state.VisualData.Dictionary,
    LAN: state.VisualData.LAN
});

export default connect(mapStateToProps)(FlightListOriginDestination);